import React, { useEffect, useState } from 'react';

import client from 'client';
import useMessages from 'Messages';

import Box from 'components/Box';

import './Usage.css';

const getPref = () => localStorage.getItem('usage_preference') || 'standard';
const storePref = (value) => localStorage.setItem('usage_preference', value);

const Usage = () => {
  const [pref, setPref] = useState(getPref());
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [usage, setUsage] = useState();

  const [messages, setMessages] = useMessages();

  useEffect(() => {
    const message = messages.find((m) => m.type === 'clip_created');

    if (!message) return;

    setShouldUpdate(true);

    return () => setMessages((ms) => ms.filter((m) => m !== message));
  }, [messages, setMessages]);

  useEffect(() => {
    if (!shouldUpdate) return;

    // timeout so that we can batch 'clip_created' messages
    const tId = setTimeout(() => {
      client.usage.get().then(({ error, message, ...usage }) => {
        if (!error) setUsage(usage);
      });

      setShouldUpdate(false);
    }, 1000);

    return () => clearTimeout(tId);
  }, [shouldUpdate]);

  if (!usage) return null;

  const changePreference = () => {
    const nextPref = pref === 'standard' ? 'wavenet' : 'standard';
    storePref(nextPref);
    setPref(nextPref);
  };

  const ratio = (usage.monthly[pref] * 100) / usage.limit[pref];
  const percent = Math.floor(ratio);

  const status = percent < 80 ? 'low' : percent < 95 ? 'high' : 'danger';

  return (
    <li className="navigation__item" onClick={() => changePreference()}>
      <Box className={`usage usage--${status}`}>
        <Box className="usage__title">USAGE</Box>
        <Box className="usage__label">{pref}</Box>
        <Box className="usage__value">{percent}%</Box>
      </Box>
    </li>
  );
};

export default Usage;
