import React from 'react';

import './Card.css';

const Card = (props) => {
  const { children, stretch } = props;

  let classname = 'card';
  classname = classname + (stretch ? ' card--stretch' : '');

  return <div className={classname}>{children}</div>;
};

export default Card;
