import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Icon from 'components/Icon';
import Navigation from './Navigation';
import { ReactComponent as LogoIcon } from './logo.svg';
import { routes } from 'Routes';

import './Header.css';

const Header = (props) => {
  const [navIsOpen, setNavIsOpen] = useState(false);

  const { pathname } = useLocation();

  const shouldTruncate = pathname !== '/' && pathname.endsWith('/');
  const currentPath = shouldTruncate ? pathname.slice(-1) : pathname;
  const { isPrivate } = routes.find((r) => r.path === currentPath) || {};

  const brandLink = isPrivate ? '/clips' : '/';

  return (
    <header className="header">
      <div className="header__body">
        <Link className="header__brand" to={brandLink}>
          <LogoIcon />
          <span className="header__name">Hearling</span>
        </Link>
        <Navigation
          isPrivate={isPrivate}
          open={navIsOpen}
          setOpen={setNavIsOpen}
        />
        <Icon
          className="header__menu"
          dark
          icon="menu"
          onClick={() => setNavIsOpen(true)}
        />
      </div>
    </header>
  );
};

export default Header;
