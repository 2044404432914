import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/browser';
import config from './config';
import App from './App';

ReactGA.initialize('UA-101624095-6');

Sentry.init({
  dsn:
    'https://8a4bb68a9b2141f0970b96702c6c9fd0@o394752.ingest.sentry.io/5245559',
  environment: process.env.NODE_ENV,
  release: config.sentry.release,
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);
