import React from 'react';

import './Columns.css';

const Columns = (props) => {
  const { children, className, id } = props;

  let classname = 'columns';
  classname = classname + (className ? ` ${className}` : '');

  return (
    <div className={classname} id={id}>
      {children}
    </div>
  );
};

const Column = (props) => {
  const { auto, children, className, float, scale, width } = props;

  const style = { width };

  let classname = 'column';
  classname = classname + (auto ? ' column--auto' : '');
  classname = classname + (float ? ' column--float' : '');
  classname = classname + (scale ? ` column--${scale}` : '');
  classname = classname + (className ? ` ${className}` : '');

  return (
    <div className={classname} style={style}>
      {children}
    </div>
  );
};

export default Columns;
export { Column };
