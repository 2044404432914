import React, { useEffect, useState } from 'react';

import Editor from './Editor';
import Page from 'components/Page';
import Panes, { Pane } from 'components/Panes';
import Queue from './Queue';
import client from 'client';

const limits = {
  queue: { free: 10, pro: 50, pro_plus: 50 },
  clip: 5000,
};

const newClip = (clips) => selectClip([...clips, { text: '' }], clips.length);

const selectClip = (clips, select) =>
  clips.map(({ text }, i) =>
    i === select ? { text, selected: true } : { text }
  );

const selectValidClip = (clips) => {
  if (clips.length === 1) return clips;

  const selectedIndex = clips.findIndex((clip) => clip.selected);
  const isValidClip = clips[selectedIndex].text.length > 0;

  if (isValidClip) return clips;

  const canMoveForward = clips.length > selectedIndex + 1;
  const modifier = canMoveForward ? 1 : -1;
  const nextIndex = selectedIndex + modifier;

  return selectClip(clips, nextIndex);
};

const pruneClips = (clips) =>
  clips.filter((c) => c.selected || c.text.length > 0);

const maxLength = ({ clips, usage, type }) => {
  if (!usage) return limits.clip;

  const savedClips = clips.filter((c) => !c.selected);
  const charsInQueue = savedClips.reduce((acc, c) => acc + c.text.length, 0);
  const remainingUsage = usage.remaining[type];

  const remainingChars = remainingUsage - charsInQueue;

  return remainingChars > limits.clip ? limits.clip : remainingChars;
};

const remainingChars = ({ usage, type }) => {
  if (!usage) return null;

  return usage.remaining[type];
};

const ClipForm = (props) => {
  const { onBack, onSubmit, voice } = props;

  const [account, setAccount] = useState();
  const [clips, setClips] = useState(newClip([]));
  const [queueIsActive, setQueueIsActive] = useState(true);
  const [usage, setUsage] = useState();

  const queueLimit = account ? limits.queue[account.type] : limits.queue.free;
  const queueRemaining = queueLimit - clips.length;

  const handleClipChange = (nextText) => {
    const nextClips = clips.map(({ selected, text }) =>
      selected ? { selected, text: nextText } : { text }
    );

    setClips(nextClips);
  };

  const handleCreation = () => {
    if (clips.length >= queueLimit) return;

    const withNewClip = newClip(clips);
    const nextClips = pruneClips(withNewClip);

    setClips(nextClips);
    setQueueIsActive(false);
  };

  const handleSelection = (i) => {
    const withSelectedClip = selectClip(clips, i);
    const nextClips = pruneClips(withSelectedClip);

    setClips(nextClips);
    setQueueIsActive(false);
  };

  const handleSave = () => {
    const withValidSelection = selectValidClip(clips);
    const nextClips = pruneClips(withValidSelection);

    setClips(nextClips);
    setQueueIsActive(true);
  };

  const handleImport = (texts) => {
    const newClips = texts.map((text) => ({ text }));
    const combinedClips = [...clips, ...newClips];
    const withSelection = selectClip(combinedClips, combinedClips.length - 1);
    const nextClips = pruneClips(withSelection);

    setClips(nextClips);
    setQueueIsActive(true);
  };

  const handleSubmit = () => {
    const clipsToSubmit = clips.filter((clip) => clip.text.length > 0);

    onSubmit(clipsToSubmit);
  };

  useEffect(() => {
    (async () => {
      const account = await client.accounts.profile();
      const usage = await client.usage.get();

      setAccount(account);
      setUsage(usage);
    })();
  }, []);

  const charLimit = maxLength({ clips, usage, type: voice.type });
  const monthlyRemaining = remainingChars({ usage, type: voice.type });

  const selectedClip = clips.find((c) => c.selected);
  const selectedIndex = clips.indexOf(selectedClip);

  return (
    <Page fixed>
      <Panes>
        <Pane active={queueIsActive} medium>
          <Queue
            account={account}
            clips={clips}
            limit={queueLimit}
            onBack={onBack}
            onCreate={handleCreation}
            onSelect={handleSelection}
            onSubmit={handleSubmit}
            voice={voice}
          />
        </Pane>
        <Pane active={!queueIsActive}>
          <Editor
            account={account}
            clip={selectedClip}
            focus={!queueIsActive}
            index={selectedIndex}
            maxLength={charLimit}
            monthlyRemaining={monthlyRemaining}
            onChange={handleClipChange}
            onImport={handleImport}
            onQuickSave={handleCreation}
            onSave={handleSave}
            queueRemaining={queueRemaining}
          />
        </Pane>
      </Panes>
    </Page>
  );
};

export default ClipForm;
