import React from 'react';

import Card from 'components/Card';
import Heading from 'components/Heading';
import Page from 'components/Page';
import Section from 'components/Section';
import Text from 'components/Text';
import useAuthentication from 'Authentication';

const Deleted = () => {
  const [, setAccount] = useAuthentication();

  React.useEffect(() => setAccount({}), [setAccount]);

  return (
    <Page centered dim>
      <Section small>
        <Card stretch>
          <Heading center status="1">
            Account Deleted
          </Heading>
          <Text>
            Your account has been deleted and you can now close this page.
            Goodbye!
          </Text>
        </Card>
      </Section>
    </Page>
  );
};

export default Deleted;
