import React, { useEffect, useState } from 'react';

import Card from 'components/Card';
import Detail from '../Detail';
import Heading from 'components/Heading';
import List from 'components/List';
import client from 'client';
import dates from 'dates';
import { Strong } from 'components/Text';

import './Billing.css';

const plans = {
  free: 'Free',
  pro: 'Pro',
  pro_plus: 'Pro Plus',
};

const formatPlan = ({ account, subscription }) => {
  const plan = plans[account.type];
  return subscription?.cancelAtPeriodEnd ? `${plan} (canceled)` : plan;
};

const Billing = ({ account }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subscription, setSubscription] = useState();

  const openBillingPortal = async () => {
    setIsLoading(true);
    const { redirect } = await client.subscriptions.portal();
    window.location = redirect;
  };

  const hasSubscription = account.type.includes('pro');
  const plan = formatPlan({ account, subscription });

  useEffect(() => {
    if (!hasSubscription) return;

    client.subscriptions
      .show()
      .then(({ error, ...subscription }) => {
        if (!error) setSubscription(subscription);
      })
      .catch(() => {});
  }, [hasSubscription]);

  return (
    <Card>
      <Heading status="2">Billing</Heading>
      <List className="details">
        <Detail
          centered
          link={
            hasSubscription ? null : '/accounts/upgrade?return=/accounts/me'
          }
          title="Plan"
          value={plan}
        />
        <Detail
          centered
          title="Next Payment"
          value={<NextPayment subscription={subscription} />}
        />
        {hasSubscription && (
          <Detail
            onClick={isLoading ? null : openBillingPortal}
            title={isLoading ? 'Redirecting...' : 'Manage Billing'}
          />
        )}
        {subscription?.cancelAtPeriodEnd && (
          <Detail link="/subscriptions/cancel" title="Cancel Immediately" />
        )}
      </List>
    </Card>
  );
};

const NextPayment = ({ subscription }) => {
  const hasPayments = subscription && !subscription.cancelAtPeriodEnd;

  if (!hasPayments) return <Strong>$0</Strong>;

  const { period, plan } = subscription;

  const dollarAmount = plan.amount / 100;
  const currencyStyle = { style: 'currency', currency: 'USD' };

  return (
    <>
      <Strong>{dollarAmount.toLocaleString(undefined, currencyStyle)}</Strong>{' '}
      on <Strong>{dates.format.nextPayment(period.end)}</Strong>
    </>
  );
};

export default Billing;
