import React from 'react';
import { Redirect } from 'react-router-dom';

import Page from 'components/Page';
import useAuth from 'Authentication';

const Delete = () => {
  const [account, setAccount] = useAuth();

  if (account.token) setAccount({});

  if (!account.token) return <Redirect to="/" />;

  return <Page centered>Signing out...</Page>;
};

export default Delete;
