import React from 'react';

import List, { ListHeader, ListItem } from 'components/List';

const defaultRender = (item) => item;

const ListSelect = (props) => {
  const { header, items, loading, onSelect, select } = props;
  const render = props.children || defaultRender;

  const isSelected = items.includes(select);

  return (
    <List
      header={header && <ListHeader>{header}</ListHeader>}
      loading={loading}
    >
      {isSelected ? (
        <ListItem onClick={() => onSelect(null)} selected>
          {render(select)}
        </ListItem>
      ) : (
        items.map((item, i) => (
          <ListItem key={i} onClick={() => onSelect(item, i)}>
            {render(item)}
          </ListItem>
        ))
      )}
    </List>
  );
};

export default ListSelect;
