import React from 'react';

import './Table.css';

const Table = ({ children }) => {
  const [hasLeftShadow, setHasLeftShadow] = React.useState(false);
  const [hasRightShadow, setHasRightShadow] = React.useState(false);

  const wrapper = React.useRef();
  const edgeLeft = React.useRef();
  const edgeRight = React.useRef();

  React.useEffect(() => {
    if (!wrapper.current) return;

    const observer = new IntersectionObserver(
      (edges) =>
        edges.forEach((edge) => {
          if (edge.target === edgeLeft.current)
            setHasLeftShadow(!edge.isIntersecting);
          if (edge.target === edgeRight.current)
            setHasRightShadow(!edge.isIntersecting);
        }),
      {
        root: wrapper.current,
        threshold: 1.0,
      }
    );

    observer.observe(edgeLeft.current);
    observer.observe(edgeRight.current);

    return () => observer.disconnect();
  }, [setHasLeftShadow, setHasRightShadow]);

  return (
    <div className="table-wrapper" ref={wrapper}>
      <div ref={edgeLeft} />
      <Shadow left show={hasLeftShadow} />
      <div className="table">{children}</div>
      <Shadow right show={hasRightShadow} />
      <div ref={edgeRight} />
    </div>
  );
};

const Shadow = ({ left, right, show }) => {
  let classname = 'shadow';
  classname += left ? ' shadow--left' : '';
  classname += right ? ' shadow--right' : '';
  classname += show ? ' shadow--show' : '';

  return <div className={classname} />;
};

const Row = (props) => {
  const { children, header, rounded } = props;

  let classname = 'row';
  classname = classname + (header ? ' row--header' : '');
  classname = classname + (rounded ? ' row--rounded' : '');

  return <div className={classname}>{children}</div>;
};

const Column = (props) => {
  const { children, hidden, highlight } = props;

  let classname = 'column';
  classname = classname + (hidden ? ' column--hidden' : '');
  classname = classname + (highlight ? ' column--highlight' : '');

  return <div className={classname}>{children}</div>;
};

export default Table;
export { Column, Row };
