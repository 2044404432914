import React from 'react';

import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import Heading from 'components/Heading';
import Link from 'components/Link';
import Page from 'components/Page';
import Section from 'components/Section';
import Text from 'components/Text';
import client from 'client';

const wait = (fn, ms) =>
  new Promise((resolve) => setTimeout(() => resolve(fn()), ms));

const hasSubscription = async () => {
  const account = await client.accounts.profile();
  if (account?.type?.includes('pro')) return account;
  else throw new Error(false);
};

const waitForSubscription = (attempts = 5) =>
  hasSubscription().catch(() => {
    if (attempts > 1)
      return wait(() => waitForSubscription(attempts - 1), 5000);
    else return null;
  });

const Tada = () => (
  <span aria-label="partying face" role="img">
    🥳
  </span>
);

const Loading = () => (
  <Page centered dim>
    <Text>Checking for subscription. This could take up to 30 seconds...</Text>
  </Page>
);

const Success = () => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [account, setAccount] = React.useState();

  React.useEffect(() => {
    waitForSubscription()
      .then((account) => setAccount(account))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <Loading />;

  return (
    <Page centered dim>
      <Section small>
        <Card stretch>
          {account ? <SuccessMessage account={account} /> : <ErrorMessage />}
        </Card>
      </Section>
    </Page>
  );
};

const plans = {
  free: 'Free',
  pro: 'Pro',
  pro_plus: 'Pro Plus',
};

const SuccessMessage = ({ account }) => {
  const plan = plans[account.type];

  return (
    <>
      <Heading status="1">
        Thank you! <Tada />
      </Heading>
      <Text>
        You are now a {plan} user. You can manage your subscription from your{' '}
        <Link to="/accounts/me">Account</Link> page at any time.
      </Text>
      <Box mt="1rem">
        <Button link="/clips" stretch>
          Continue
        </Button>
      </Box>
    </>
  );
};

const ErrorMessage = () => (
  <>
    <Heading status="1">Oh no!</Heading>
    <Text>
      Your payment succeeded, but something went wrong when creating the
      subscription. Please wait a few minutes and then refresh the page.
    </Text>
    <Text>
      If that doesn't work, please send us an{' '}
      <Link external to="mailto:contact@hearling.com">
        email
      </Link>{' '}
      with your account details so that we can look into it.
    </Text>
  </>
);

export default Success;
