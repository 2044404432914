import React, { useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import Alert from 'components/Alert';
import Button from 'components/Button';
import Card from 'components/Card';
import Form, { Field } from 'components/Form';
import Heading from 'components/Heading';
import Page from 'components/Page';
import Section from 'components/Section';
import client from 'client';
import useMessages from 'Messages';

const msgSuccess = {
  level: 'success',
  type: 'alert',
  value: 'Password updated!',
};

const ResetPassword = () => {
  const [alert, setAlert] = useState();
  const [password, setPassword] = useState('');
  const [isUpdated, setIsUpdated] = useState(false);

  const location = useLocation();
  const [, setMessages] = useMessages();

  const handleChange = (event) => setPassword(event.target.value);

  const handleSubmit = (event) => {
    event.preventDefault();

    const params = new URLSearchParams(location.search);
    const token = params.get('token') || '';

    client.accounts
      .resetPassword({ password, token })
      .then(({ error, message }) => {
        if (error) return setAlert(message);

        setMessages((ms) => [...ms, msgSuccess]);
        setIsUpdated(true);
      });
  };

  if (isUpdated) return <Redirect to="/sessions/new" />;

  return (
    <Page centered dim>
      <Section small>
        {alert && (
          <Alert icon="error" level="error">
            {alert}
          </Alert>
        )}
        <Card stretch>
          <Heading status="1">Reset Password</Heading>
          <Form onSubmit={handleSubmit}>
            <Field
              name="password"
              onChange={handleChange}
              placeholder="Password"
              type="password"
              value={password}
            />
            <Button stretch>Reset Password</Button>
          </Form>
        </Card>
      </Section>
    </Page>
  );
};

export default ResetPassword;
