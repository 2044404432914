import React, { useEffect, useState } from 'react';

import AccountDetails from './AccountDetails';
import Activity from './Activity';
import Billing from './Billing';
import Box from 'components/Box';
import Alert from 'components/Alert';
import Page from 'components/Page';
import Section from 'components/Section';
import client from 'client';
import useMessages from 'Messages';

import './Profile.css';

const Profile = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState({});
  const [usage, setUsage] = useState();

  const [alert, setAlert] = useState();
  const [messages, setMessages] = useMessages();

  useEffect(() => {
    const message = messages.find((m) => m.type === 'alert');

    setAlert(message);

    return () => setMessages((ms) => ms.filter((m) => m !== message));
  }, [messages, setMessages]);

  useEffect(() => {
    (async () => {
      const account = await client.accounts.profile();
      const usage = await client.usage.get();

      setAccount(account);
      setUsage(usage);
      setIsLoading(false);
    })();
  }, []);

  return (
    <Page dim loading={isLoading}>
      <Section large>
        {!account.verified && <VerifiedAlert />}
        {alert && (
          <Alert icon={alert.level} level={alert.level}>
            {alert.value}
          </Alert>
        )}
        <Box className="groups">
          <Box className="group">
            <Activity usage={usage} />
          </Box>
          <Box className="group">
            <AccountDetails account={account} />
            <Billing account={account} />
          </Box>
        </Box>
      </Section>
    </Page>
  );
};

const VerifiedAlert = () => {
  const [hasSent, setHasSent] = useState(false);

  const handleEmail = async () => {
    await client.accounts.sendVerificationEmail();
    setHasSent(true);
  };

  if (hasSent)
    return (
      <Alert icon="success" level="success">
        Email sent!
      </Alert>
    );

  return (
    <Alert icon="warning" level="warning">
      <span>
        Please verify your email address.{' '}
        <button id="send-verification" onClick={handleEmail}>
          Click here
        </button>{' '}
        to send verification email again.
      </span>
    </Alert>
  );
};

export default Profile;
