import React from 'react';

import './Icon.css';

import iconLoading from './icons/loading.png';
import iconLoadingWhite from './icons/loading-white.png';
import { ReactComponent as AnnounceIcon } from './icons/announce.svg';
import { ReactComponent as CheckmarkIcon } from './icons/checkmark.svg';
import { ReactComponent as CloseIcon } from './icons/close.svg';
import { ReactComponent as DownloadIcon } from './icons/download.svg';
import { ReactComponent as ErrorIcon } from './icons/error.svg';
import { ReactComponent as ExpandLessIcon } from './icons/expand-less.svg';
import { ReactComponent as ExpandMoreIcon } from './icons/expand-more.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as LockIcon } from './icons/lock.svg';
import { ReactComponent as MenuIcon } from './icons/menu.svg';
import { ReactComponent as NotifyIcon } from './icons/notify.svg';
import { ReactComponent as PauseIcon } from './icons/pause.svg';
import { ReactComponent as PlayIcon } from './icons/play.svg';
import { ReactComponent as SearchIcon } from './icons/search.svg';
import { ReactComponent as StopIcon } from './icons/stop.svg';
import { ReactComponent as SuccessIcon } from './icons/success.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';

const LoadingIcon = () => <img alt="loading" src={iconLoading} />;
const LoadingIconWhite = () => <img alt="loading" src={iconLoadingWhite} />;

const icons = {
  announce: AnnounceIcon,
  checkmark: CheckmarkIcon,
  close: CloseIcon,
  download: DownloadIcon,
  error: ErrorIcon,
  expandLess: ExpandLessIcon,
  expandMore: ExpandMoreIcon,
  info: InfoIcon,
  loading: LoadingIcon,
  loadingWhite: LoadingIconWhite,
  lock: LockIcon,
  notify: NotifyIcon,
  menu: MenuIcon,
  pause: PauseIcon,
  play: PlayIcon,
  search: SearchIcon,
  stop: StopIcon,
  success: SuccessIcon,
  warning: WarningIcon,
};

const Icon = (props) => {
  const { className, disabled, download, icon, onClick, title } = props;

  const Image = icons[icon];

  const Container = download ? 'a' : onClick ? 'button' : 'span';

  let classname = 'icon';
  classname = classname + (disabled ? ' icon--disabled' : '');
  classname = classname + (props.dark ? ' icon--dark' : '');
  classname = classname + (props.spin ? ' icon--spin' : '');
  classname = classname + (props.white ? ' icon--white' : '');
  classname = classname + (props.className ? ` ${className}` : '');

  return (
    <Container
      download={!!download}
      href={download ? download : null}
      className={classname}
      onClick={disabled ? null : onClick}
      title={title ? title : null}
    >
      <Image />
    </Container>
  );
};

export default Icon;
