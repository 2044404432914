import React, { useEffect, useState } from 'react';

import AudioIcon from 'components/Icon/AudioIcon';
import Heading from 'components/Heading';
import Page from 'components/Page';
import Section from 'components/Section';
import Table, { Column, Row } from 'components/Table';
import Text from 'components/Text';
import client from 'client';
import { parseVoice } from 'voices';

import './Voices.css';

const sortVoices = (voices) =>
  voices.sort((a, b) => {
    if (a.language < b.language) return -1;
    if (a.language > b.language) return 1;

    if (a.dialect < b.dialect) return -1;
    if (a.dialect > b.dialect) return 1;

    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;

    return 0;
  });

const Voices = () => {
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    client.voices
      .list()
      .then((voices) => voices.map(parseVoice))
      .then(sortVoices)
      .then(setVoices);
  }, []);

  return (
    <Page loading={!voices.length}>
      <Section id="voices">
        <Heading status="1">Voices</Heading>
        <Text>
          Currently, all voices are sourced from Google's Cloud Text-To-Speech
          engine, which supports over 200 voices.
        </Text>
        <Table>
          <Row header>
            <Column>Sample</Column>
            <Column>Language</Column>
            <Column>Dialect</Column>
            <Column>Quality</Column>
            <Column>SSML Gender</Column>
            <Column>Name</Column>
          </Row>
          {voices.map((voice) => (
            <Row key={voice.id}>
              <Column>
                <AudioIcon src={client.samples.get({ voice: voice.id })} />
              </Column>
              <Column>{voice.language}</Column>
              <Column>{voice.dialect}</Column>
              <Column>{voice.quality}</Column>
              <Column>{voice.gender}</Column>
              <Column>{voice.name}</Column>
            </Row>
          ))}
        </Table>
      </Section>
    </Page>
  );
};

export default Voices;
