import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import Alert from 'components/Alert';
import Button from 'components/Button';
import Icon from 'components/Icon';
import Usage from './Usage';
import client from 'client';

import './Navigation.css';

const Navigation = (props) => {
  const { isPrivate, open, setOpen } = props;

  const [account, setAccount] = useState();

  const classnames = 'navigation' + (open ? ' navigation--open' : '');

  useEffect(() => {
    if (!isPrivate) return;

    client.accounts.profile().then(({ error, message, ...account }) => {
      if (!error) setAccount(account);
    });
  }, [isPrivate]);

  return (
    <nav className={classnames}>
      <div className="navigation__overlay" onClick={() => setOpen(false)} />
      <div className="navigation__body">
        <ul className="navigation__list">
          {isPrivate ? (
            <>
              <TryPro account={account} />
              <Usage />
              <PrivateLinks onNavigate={() => setOpen(false)} />
            </>
          ) : (
            <PublicLinks onNavigate={() => setOpen(false)} />
          )}
        </ul>
        <Icon
          className="navigation__close"
          dark
          icon="close"
          onClick={() => setOpen(false)}
        />
      </div>
    </nav>
  );
};

const TryPro = ({ account }) => {
  if (account?.type?.includes('pro')) return null;

  return (
    <li className="navigation__item">
      <Link to="/accounts/upgrade">
        <Alert icon="notify" level="success">
          Try Pro
        </Alert>
      </Link>
    </li>
  );
};

const PrivateLinks = ({ onNavigate }) => (
  <>
    <NavigationItem link="/help" onNavigate={onNavigate} name="Help" />
    <NavigationItem link="/clips" onNavigate={onNavigate} name="Clips" />
    <NavigationItem
      link="/accounts/me"
      onNavigate={onNavigate}
      name="Account"
    />
    <NavigationItem
      link="/sessions/delete"
      onNavigate={onNavigate}
      name="Sign out"
    />
  </>
);

const PublicLinks = ({ onNavigate }) => (
  <>
    <NavigationItem link="/help" onNavigate={onNavigate} name="Help" />
    <NavigationItem link="/pricing" onNavigate={onNavigate} name="Pricing" />
    <NavigationItem
      link="/sessions/new"
      onNavigate={onNavigate}
      name="Sign In"
    />
    <NavigationItem
      button
      link="/accounts/new"
      onNavigate={onNavigate}
      name="Sign Up"
    />
  </>
);

const NavigationItem = (props) => {
  const { button, link, onNavigate, name } = props;

  return (
    <li className="navigation__item">
      {button ? (
        <Button link={link} onClick={onNavigate}>
          {name}
        </Button>
      ) : (
        <NavLink className="navigation__link" onClick={onNavigate} to={link}>
          {name}
        </NavLink>
      )}
    </li>
  );
};

export default Navigation;
