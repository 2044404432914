import React, { useState } from 'react';

import Box from 'components/Box';
import Columns, { Column } from 'components/Columns';
import Icon from 'components/Icon';
import Text from 'components/Text';
import useBreakpoints from 'Breakpoints';
import { ListItem } from 'components/List';
import { parseVoice } from 'voices';

import './Clip.css';

const Clip = (props) => {
  const { clip, onClick, selected } = props;

  const breakpoints = useBreakpoints();

  return (
    <ListItem
      className="clip"
      flush={breakpoints.small}
      onClick={() => onClick(clip)}
      selected={selected}
    >
      {breakpoints.small ? (
        <SmallLayout clip={clip} selected={selected} />
      ) : (
        <RegularLayout clip={clip} selected={selected} />
      )}
    </ListItem>
  );
};

const SmallLayout = ({ clip, selected }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = (event) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const border = isExpanded ? '1px solid var(--c-gray-300)' : null;

  const { dialect, language, name, quality } = parseVoice(clip.voice);

  return (
    <Box flex flexDirection="column">
      <Box borderBottom={border} p="1rem">
        <Columns>
          <Column width="2rem">
            <ClipIcon name={name} selected={selected} />
          </Column>
          <Column auto width="100%">
            <Text lines={2}>{clip.text}</Text>
          </Column>
          <Column width="2.125rem">
            <Icon
              icon={isExpanded ? 'expandLess' : 'expandMore'}
              onClick={handleToggleExpand}
            />
          </Column>
        </Columns>
      </Box>
      {isExpanded && (
        <Box className="clip__expansion">
          <Columns>
            <Column auto>
              <Box>
                <Text bold caps tiny>
                  Dialect:
                </Text>
              </Box>
              <Box mt="0.5rem">
                <Text className="clip__language" tiny>
                  {language} ({dialect})
                </Text>
              </Box>
            </Column>
            <Column auto>
              <Box>
                <Text bold caps tiny>
                  Voice:
                </Text>
              </Box>
              <Box mt="0.5rem">
                <Text className="clip__voice" tiny>
                  {name} ({quality})
                </Text>
              </Box>
            </Column>
          </Columns>
        </Box>
      )}
    </Box>
  );
};

const RegularLayout = ({ clip, selected }) => {
  const { dialect, language, name, quality } = parseVoice(clip.voice);

  return (
    <Columns>
      <Column width="2rem">
        <ClipIcon name={name} selected={selected} />
      </Column>
      <Column auto width="100%">
        <Text lines={2}>{clip.text}</Text>
      </Column>
      <Column width="10rem">
        <Text className="clip__language">
          {language} ({dialect})
        </Text>
      </Column>
      <Column width="6rem">
        <Text className="clip__voice">
          {name} ({quality})
        </Text>
      </Column>
    </Columns>
  );
};

const ClipIcon = ({ name, selected }) => {
  let classname = 'clip__icon';
  classname = classname + (selected ? ' clip__icon--selected' : '');

  return (
    <div className={classname}>
      {selected ? <Icon icon="checkmark" white /> : name}
    </div>
  );
};

export default Clip;
