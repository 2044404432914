import React from 'react';

import Box from 'components/Box';
import Text from 'components/Text';
import Section from 'components/Section';

import './Footer.css';

const Love = () => (
  <span aria-label="love" role="img">
    ♥️
  </span>
);

const Footer = () => {
  return (
    <footer className="footer">
      <Section>
        <Text bold center>
          Made with <Love /> by <a href="https://flora.cool">Flora Moon</a>
        </Text>
        <Box flex justifyContent="center">
          •
        </Box>
        <Text bold center>
          <a href="https://twitter.com/@HearlingApp">Twitter</a>
          <span> | </span>
          <a href="mailto:contact@hearling.com">Email</a>
        </Text>
      </Section>
    </footer>
  );
};

export default Footer;
