import React from 'react';
import * as V from 'victory';

import Text from 'components/Text';
import { toLanguage } from 'voices';

const groupBy = (as, fn) =>
  as.reduce((res, a) => {
    const key = fn(a);
    if (res[key]) res[key].push(a);
    else res[key] = [a];
    return res;
  }, {});

const LanguageBars = ({ data }) => {
  const languages = groupBy(data, (d) => d.language.split('-')[0]);

  const bars = Object.entries(languages)
    .map(([key, vs]) => ({
      language: vs[0].language,
      x: key,
      y: vs.length,
    }))
    .sort((a, b) => b.y - a.y)
    .slice(0, 10); // top 10 only

  return (
    <>
      <Text center>
        Clips Per Language (Top 10)
        <br />
        (All Time)
      </Text>
      <V.VictoryChart
        containerComponent={
          <V.VictoryVoronoiContainer
            domain={{ y: [0, 1] }}
            labels={({ datum }) =>
              `${toLanguage({ languageCode: datum.language })}: ${datum.y}`
            }
            labelComponent={<V.VictoryTooltip />}
          />
        }
      >
        <V.VictoryAxis />
        <V.VictoryBar data={bars} style={{ data: { fill: '#df2076' } }} />
      </V.VictoryChart>
    </>
  );
};

export default LanguageBars;
