const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const isSameDay = (test, sample) => {
  const isSameYear = test.getFullYear() === sample.getFullYear();
  const isSameMonth = test.getMonth() === sample.getMonth();
  const isSameDay = test.getDate() === sample.getDate();

  return isSameYear && isSameMonth && isSameDay;
};

const clipHistory = (string) => {
  const [year, month, day] = string.split('-').map((x) => Number(x));

  const today = new Date();
  const date = new Date(year, month - 1, day);
  const isToday = isSameDay(today, date);

  if (isToday) return 'Today';

  const isSameYear = today.getFullYear() === year;

  const yearChunk = isSameYear ? '' : `, ${year}`;

  return `${months[month - 1]} ${day}${yearChunk}`;
};

const chartTick = (string) => {
  const [year, month] = string.split('-');
  const monthIndex = Number(month);

  return `${months[monthIndex]} '${year.slice(2)}`;
};

const padNumber = (n) => `0${n}`.slice(-2);

const dateString = (string) => {
  const date = new Date(string);

  const year = date.getFullYear();
  const month = padNumber(date.getMonth() + 1);
  const day = padNumber(date.getDate());

  return `${year}-${month}-${day}`;
};

const nextPayment = (string) => {
  const date = new Date(string);
  const month = date.getUTCMonth();

  return `${months[month]} ${date.getUTCDate()}`;
};

const getLastMonth = () => {
  let month = new Date();

  month.setUTCMonth(new Date().getUTCMonth() - 1);

  return month;
};

const usageSince = (anchor) => {
  const usageDate = new Date(anchor).getUTCDate();
  const lastMonth = getLastMonth();

  const hasCycledThisMonth = usageDate <= new Date().getUTCDate();
  const sinceMonth = hasCycledThisMonth ? new Date() : lastMonth;
  const month = sinceMonth.getUTCMonth();

  return `${months[month]} ${usageDate}`;
};

const format = {
  chartTick,
  clipHistory,
  dateString,
  nextPayment,
  usageSince,
};

const dates = { format };

export default dates;
