import React, { useEffect, useRef, useState } from 'react';

import Alert from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button';
import Section from 'components/Section';
import Text from 'components/Text';
import { TextArea } from 'components/Form';

import './FileEditor.css';

const placeholder = `Paste file contents here...`;

const FileEditor = React.forwardRef((props, ref) => {
  const { maxLength, onImport, queueRemaining } = props;

  const [alert, setAlert] = useState(null);
  const [text, setText] = useState('');

  const inputEl = useRef();

  const handleFileSelected = () => {
    (async () => {
      let file = inputEl.current.files[0];

      inputEl.current.value = null;

      if (file.type !== 'text/plain')
        return setAlert(
          'Invalid file type selected. Only plaintext files are allowed.'
        );

      let text = await file.text();

      setText(text);
    })();
  };

  const handleTextChange = (text) => setText(text);

  const lines = text.split(/\r?\n/).filter((t) => t.length > 0);

  const isWithinCharLimit = text.length <= maxLength;
  const isWithinQueueLimit = lines.length <= queueRemaining;
  const isAcceptable = isWithinQueueLimit && isWithinCharLimit;

  const handleSubmit = () => onImport(lines);

  useEffect(() => {
    if (!isWithinCharLimit)
      return setAlert(
        `Too many characters. You have either exceeded your monthly usage limit or the maximum clip length. Please keep character count below: ${maxLength.toLocaleString()}`
      );

    if (!isWithinQueueLimit) return setAlert('Clip count exceeds queue limit.');

    setAlert(null);
  }, [text, isWithinQueueLimit, isWithinCharLimit, maxLength]);

  return (
    <Section id="file-editor">
      {alert && (
        <Alert icon="error" level="error">
          {alert}
        </Alert>
      )}
      <Text>
        Choose a file from your device, or paste the contents of the file below.
        A clip will be created for each line of text in the file.{' '}
        <strong>
          Please note that only plaintext files (.txt) are supported.
        </strong>
      </Text>
      <Box mt="1rem" mb="1rem">
        <input
          accept=".txt"
          id="file"
          onChange={handleFileSelected}
          ref={inputEl}
          type="file"
        />
        <label htmlFor="file">Choose File</label>
      </Box>
      <Text>or paste contents below:</Text>
      <TextArea
        autoFocus
        maxLength={maxLength}
        onChange={handleTextChange}
        onSubmit={handleSubmit}
        placeholder={placeholder}
        ref={ref}
        value={text}
      />
      <Button disabled={!isAcceptable} onClick={handleSubmit} stretch>
        Add ({lines.length}) Clips to Queue
      </Button>
    </Section>
  );
});

export default FileEditor;
