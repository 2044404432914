import React from 'react';

import Alert from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import Heading from 'components/Heading';
import Page from 'components/Page';
import Section from 'components/Section';
import Text from 'components/Text';
import client from 'client';

const Cancel = () => {
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const handleCancel = () => {
    setLoading(true);

    (async () => {
      const { error, message } = await client.subscriptions.cancel();
      setLoading(false);

      if (error) setError(message);
      else setSuccess(true);
    })();
  };

  if (success) return <SuccessMessage />;

  return (
    <Page centered dim>
      <Section>
        {error && (
          <Alert icon="error" level="error">
            {error}
          </Alert>
        )}
        <Card stretch>
          <Heading center status="1">
            Cancel Subscription
          </Heading>
          <Box mt="1rem">
            <Alert level="warning" icon="warning">
              You will immediately lose access to Pro features and increased
              character limits.
            </Alert>
          </Box>
          <Text>
            Your subscription is set to cancel at the end of the current billing
            period. You only need to cancel immediately if you want to upgrade
            your subscription or delete your account without waiting until the
            subscription end date. Are you sure you want to continue?
          </Text>
          <Box mt="1rem">
            <Button loading={loading} onClick={handleCancel} stretch>
              Cancel Immediately
            </Button>
          </Box>
        </Card>
      </Section>
    </Page>
  );
};

const SuccessMessage = () => (
  <Page centered dim>
    <Section small>
      <Card stretch>
        <Heading status="1">Subscription Canceled</Heading>
        <Text>Your subscription has been canceled successfully.</Text>
        <Box mt="1rem">
          <Button link="/accounts/me">Continue</Button>
        </Box>
      </Card>
    </Section>
  </Page>
);

export default Cancel;
