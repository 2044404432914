import { useEffect, useMemo, useState } from 'react';

import client from 'client';
import { toDialect, toLanguage } from 'voices';

const listVoices = (() => {
  let voices;

  return async () => {
    if (!voices) voices = await client.voices.list();

    return voices;
  };
})();

const useVoiceList = () => {
  const [voices, setVoices] = useState([]);

  useEffect(() => {
    (async () => {
      const voices = await listVoices();
      setVoices(voices);
    })();
  }, []);

  return voices;
};

const sort = (() => {
  const byName = (a, b) => a.name.localeCompare(b.name);

  return { byName };
})();

const filter = (() => {
  const byLanguage = (language) => (voice) => toLanguage(voice) === language;
  const byDialect = (dialect) => (voice) => toDialect(voice) === dialect;

  return { byLanguage, byDialect };
})();

const toVoices = ({ voices, language, dialect } = {}) => {
  const voicesInLanguage = voices.filter(filter.byLanguage(language));

  if (!dialect) return voicesInLanguage;

  const voicesInDialect = voicesInLanguage.filter(filter.byDialect(dialect));

  return voicesInDialect.sort(sort.byName);
};

const useVoices = ({ voices, language, dialect }) =>
  useMemo(() => toVoices({ voices, language, dialect }), [
    voices,
    language,
    dialect,
  ]);

const toLanguages = (voices) =>
  [...new Set(voices.map(toLanguage))].filter((v) => v).sort();

const useLanguages = (voices) => useMemo(() => toLanguages(voices), [voices]);

const toDialects = ({ voices, language }) =>
  [...new Set(toVoices({ voices, language }).map(toDialect))].sort();

const useDialects = ({ voices, language }) =>
  useMemo(() => toDialects({ voices, language }), [voices, language]);

export { useDialects, useLanguages, useVoices, useVoiceList };
