import React, { useEffect, useState } from 'react';

import Columns, { Column } from 'components/Columns';
import client from 'client';
import useBreakpoints from 'Breakpoints';
import { AudioIcon, DownloadIcon } from 'components/Icon';
import { ListHeader } from 'components/List';

const isAboveFreeLimit = ({ account, selected }) =>
  account?.type === 'free' &&
  selected.length > 1 &&
  'Free users can only download one clip at a time.';

const isAboveProLimit = ({ selected }) =>
  selected.length > 50 && 'Must select fewer than 50 clips.';

const validations = [isAboveFreeLimit, isAboveProLimit];

const ClipsHeader = ({ selected }) => {
  const breakpoints = useBreakpoints();

  const [account, setAccount] = useState();

  useEffect(() => {
    (async () => {
      const res = await client.accounts.profile();

      if (res.error) return;

      setAccount(res);
    })();
  }, []);

  const validate = {
    download: (clips) =>
      validations.reduce((msg, fn) => msg || fn({ account, selected }), false),
    play: (clips) =>
      clips.length > 1 && 'Must select only one clip in order to play.',
  };

  return (
    <>
      <ActionBar selected={selected} validate={validate} />
      <ListHeader>
        {breakpoints.small ? <SmallLayout /> : <RegularLayout />}
      </ListHeader>
    </>
  );
};

const SmallLayout = () => (
  <Columns>
    <Column width="2rem" />
    <Column auto width="100%">
      Text
    </Column>
    <Column width="2.125rem">More</Column>
  </Columns>
);

const RegularLayout = () => (
  <Columns>
    <Column width="2rem" />
    <Column auto width="100%">
      Text
    </Column>
    <Column width="10rem">Language</Column>
    <Column width="6rem">Voice</Column>
  </Columns>
);

const ActionBar = ({ selected, validate }) => {
  if (!selected.length) return null;

  return (
    <ListHeader>
      <Columns>
        <Column auto width="100%" />
        <Column width="1.5rem">
          <PlayAction selected={selected} validate={validate.play} />
        </Column>
        <Column width="1.5rem">
          <DownloadAction selected={selected} validate={validate.download} />
        </Column>
      </Columns>
    </ListHeader>
  );
};

const PlayAction = ({ selected, validate }) => {
  const [clip] = selected;
  const message = validate(selected);

  return <AudioIcon disabled={!!message} src={clip.location} title={message} />;
};

const DownloadAction = ({ selected, validate }) => {
  const [clip] = selected;
  const hasMultiple = selected.length > 1;

  const handleDownloadAll = () => {
    const clipIds = selected.map(({ id }) => id);

    (async () => {
      const res = await client.clips.archive({ clips: clipIds });

      const url = URL.createObjectURL(res.blob);

      window.location = url;

      URL.revokeObjectURL(url);
    })();
  };

  const message = validate(selected);

  return (
    <DownloadIcon
      download={hasMultiple ? null : clip.location}
      disabled={!!message}
      onClick={hasMultiple ? handleDownloadAll : null}
      title={message || null}
    />
  );
};

export default ClipsHeader;
