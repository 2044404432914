import React from 'react';

import './Pane.css';

const Panes = (props) => {
  const { children } = props;

  return <div className="panes">{children}</div>;
};

const Pane = (props) => {
  const { active, children, id, medium } = props;

  let classname = 'pane';
  classname = classname + (active ? ' pane--active' : '');
  classname = classname + (medium ? ' pane--medium' : '');

  return (
    <div className={classname} id={id}>
      {children}
    </div>
  );
};

export default Panes;
export { Pane };
