import React from 'react';
import * as V from 'victory';

const groupBy = (as, fn) =>
  as.reduce((res, a) => {
    const key = fn(a);
    if (res[key]) res[key].push(a);
    else res[key] = [a];
    return res;
  }, {});

const QualityPie = ({ data }) => {
  const qualities = groupBy(data, (d) => d.type);

  const slices = Object.entries(qualities).map(([key, vs]) => ({
    x: key,
    y: vs.length,
  }));

  return (
    <V.VictoryPie
      colorScale={['#e9639f', '#d92677']}
      data={slices}
      labelRadius={30}
      style={{
        labels: { fill: 'white', fontSize: 24, fontWeight: 'bold' },
      }}
    />
  );
};

export default QualityPie;
