import React from 'react';

import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Link from 'components/Link';
import Page from 'components/Page';
import Section from 'components/Section';
import Text, { NoBreak } from 'components/Text';
import { AudioIcon } from 'components/Icon/';

import imgClipEditor from './images/clip_editor.png';
import imgClipHistory from './images/clip_history.png';
import imgClipsDownload from './images/clips_download.png';
import imgLanguageSelect from './images/language_select.png';
import imgVoiceCustomize from './images/voice_customize.png';

import mp3AnyLanguage from './clips/any-language.mp3';
import mp3CreateClips from './clips/create-clips.mp3';
import mp3CustomizeVoice from './clips/customize-voice.mp3';
import mp3SaveYourHistory from './clips/save-your-history.mp3';
import mp3TryItForFree from './clips/try-it-for-free.mp3';
import mp3TTSForEveryone from './clips/tts-for-everyone.mp3';

import './Home.css';

const Home = () => {
  return (
    <Page id="home">
      <Section id="hero">
        <Heading center status="1">
          Text-To-Speech
          <br />
          for{' '}
          <NoBreak>
            everyone
            <AudioIcon eager noload src={mp3TTSForEveryone} />
          </NoBreak>
        </Heading>
        <Text center>
          Hearling makes it easy to create high quality audio from text.
        </Text>
        <Box flex justifyContent="center" mt="2rem">
          <Button className="button--gradient" link="/accounts/new">
            Try it for free
          </Button>
        </Box>
      </Section>
      <Section id="demo">
        <Box className="image">
          <img alt="" src={imgClipsDownload} />
        </Box>
      </Section>
      <Section id="features" large>
        <Box className="feature">
          <Box className="feature__text">
            <Heading status="2">
              Any language you{' '}
              <NoBreak>
                need
                <AudioIcon eager noload src={mp3AnyLanguage} />
              </NoBreak>
            </Heading>
            <Text>
              Hearling has support for 34 different languages. Want to make sure
              yours is included? Check our <Link to="/voices">Voices</Link>{' '}
              page.
            </Text>
          </Box>
          <Box className="feature__image image">
            <img alt="" src={imgLanguageSelect} />
          </Box>
        </Box>
        <Box className="feature">
          <Box className="feature__text">
            <Heading status="2">
              Customize the{' '}
              <NoBreak>
                voice
                <AudioIcon eager noload src={mp3CustomizeVoice} />
              </NoBreak>
            </Heading>
            <Text>
              Hearling has over 200 voices with different accents and genders.
              Find the right voice for you.
            </Text>
          </Box>
          <Box className="feature__image image">
            <img alt="" src={imgVoiceCustomize} />
          </Box>
        </Box>
        <Box className="feature">
          <Box className="feature__text">
            <Heading status="2">
              Create clips{' '}
              <NoBreak>
                faster
                <AudioIcon eager noload src={mp3CreateClips} />
              </NoBreak>
            </Heading>
            <Text>
              Create up to 50 clips at once and download them all at the same
              time.
            </Text>
          </Box>
          <Box className="feature__image image">
            <img alt="" src={imgClipEditor} />
          </Box>
        </Box>
        <Box className="feature">
          <Box className="feature__text">
            <Heading status="2">
              Save your{' '}
              <NoBreak>
                history
                <AudioIcon eager noload src={mp3SaveYourHistory} />
              </NoBreak>
            </Heading>
            <Text>
              Keep access to your clips forever, from wherever. Hearling stores
              your clips in the cloud for easy access any time.
            </Text>
          </Box>
          <Box className="feature__image image">
            <img alt="" src={imgClipHistory} />
          </Box>
        </Box>
      </Section>
      <Section id="conclusion">
        <Heading center status="2">
          Try it for{' '}
          <NoBreak>
            free
            <AudioIcon eager noload src={mp3TryItForFree} />
          </NoBreak>
        </Heading>
        <Text center>
          and pay when you are ready. Start with over 5,000 characters per month
          for free. If you ever need more, you can upgrade for just $5 per
          month.
        </Text>
        <Box flex justifyContent="center" mt="2rem">
          <Button className="button--gradient" link="/accounts/new">
            Sign up for free
          </Button>
        </Box>
      </Section>
    </Page>
  );
};

export default Home;
