import React from 'react';

const mergeProps = (props, mapping) => {
  const keys = Object.keys(mapping);

  return Object.keys(props).reduce(
    (style, prop) =>
      keys.includes(prop) ? { ...style, [mapping[prop]]: props[prop] } : style,
    {}
  );
};

const borders = (props) =>
  mergeProps(props, {
    borderBottom: 'borderBottom',
    borderLeft: 'borderLeft',
    borderRight: 'borderRight',
    borderTop: 'borderTop',
    border: 'border',
  });

const dimensions = ({ h, w }) => ({
  height: h,
  width: w,
});

const display = ({
  alignItems,
  justifyContent,
  flex,
  flexDirection,
  scroll,
  scrollX,
  scrollY,
}) => ({
  alignItems,
  display: flex && 'flex',
  flexDirection,
  justifyContent,
  overflow: scroll && 'auto',
  overflowX: scrollX && 'auto',
  overflowY: scrollY && 'auto',
});

const margins = (props) =>
  mergeProps(props, {
    mb: 'marginBottom',
    ml: 'marginLeft',
    mr: 'marginRight',
    mt: 'marginTop',
    m: 'margin',
  });

const padding = (props) =>
  mergeProps(props, {
    pb: 'paddingBottom',
    pl: 'paddingLeft',
    pr: 'paddingRight',
    pt: 'paddingTop',
    p: 'padding',
  });

const palette = ({ background }) => ({ background });

const shadows = ({ boxShadow }) => ({ boxShadow });

const Box = React.forwardRef((props, ref) => {
  const { children, className, id } = props;

  const style = {
    ...borders(props),
    ...dimensions(props),
    ...display(props),
    ...margins(props),
    ...padding(props),
    ...palette(props),
    ...shadows(props),
  };

  return (
    <div className={className} id={id} ref={ref} style={style}>
      {children}
    </div>
  );
});

export default Box;
