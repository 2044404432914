import React, { useEffect, useRef, useState } from 'react';

import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import ProLock from 'shared/ProLock';

import FileEditor from './FileEditor';
import TextEditor from './TextEditor';

import './Editor.css';

const Editor = (props) => {
  const { account, clip, focus, index } = props;

  const [isTextEditorActive, setIsTextEditorActive] = useState(true);

  const textareaEl = useRef();

  useEffect(() => {
    if (focus) textareaEl.current.focus();
  }, [clip, focus]);

  useEffect(() => {
    setIsTextEditorActive(true);
  }, [clip]);

  return (
    <Box id="editor" flex flexDirection="column" h="100%">
      <TitleBar
        account={account}
        clip={clip}
        index={index}
        isTextEditorActive={isTextEditorActive}
        setIsTextEditorActive={setIsTextEditorActive}
      />
      {isTextEditorActive ? (
        <TextEditor {...props} ref={textareaEl} />
      ) : (
        <FileEditor {...props} ref={textareaEl} />
      )}
    </Box>
  );
};

const TitleBar = (props) => {
  const {
    account,
    clip,
    index,
    isTextEditorActive,
    setIsTextEditorActive,
  } = props;

  const isClipEmpty = clip.text.length === 0;
  const isPro = account?.type.includes('pro');
  const isFileEditorEnabled = isClipEmpty && isPro;

  return (
    <Box className="info-bar">
      <Heading status="2">
        {isTextEditorActive ? `Clip ${index + 1} text` : 'Import File'}
      </Heading>
      {isTextEditorActive ? (
        <Box id="import-file">
          <Button
            disabled={!isFileEditorEnabled}
            onClick={() => setIsTextEditorActive(false)}
          >
            Import File
          </Button>
          <ProLock hide={isPro} />
        </Box>
      ) : (
        <Button onClick={() => setIsTextEditorActive(true)}>Cancel</Button>
      )}
    </Box>
  );
};

export default Editor;
