import React from 'react';
import { Link } from 'react-router-dom';

import { ListItem } from 'components/List';
import Columns, { Column } from 'components/Columns';

import './Detail.css';

const Detail = ({ centered, link, onClick, title, value }) => {
  const Wrapper = ({ children }) =>
    link ? (
      <Link className="detail__body" to={link}>
        {children}
      </Link>
    ) : (
      <div className="detail__body">{children}</div>
    );

  return (
    <ListItem className="detail" flush onClick={onClick}>
      <Wrapper>
        <Columns>
          <Column width={value ? '6.75rem' : 'auto'}>{title}</Column>
          {value && (
            <Column auto={centered} float={!centered}>
              {value}
            </Column>
          )}
          {(link || onClick) && <Column float>{'>'}</Column>}
        </Columns>
      </Wrapper>
    </ListItem>
  );
};

export default Detail;
