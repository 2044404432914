import React from 'react';

import Icon from 'components/Icon';

import './Page.css';

const Page = (props) => {
  const { centered, children, dim, fixed, id, loading } = props;

  let classname = 'page';
  classname = classname + (dim ? ' page--dim' : '');
  classname = classname + (centered || loading ? ' page--centered' : '');
  classname = classname + (fixed ? ' page--fixed' : '');

  return (
    <main className={classname} id={id}>
      {loading ? <Icon icon="loading" spin /> : children}
    </main>
  );
};

export default Page;
