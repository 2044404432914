import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import Alert from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import Form, { Field } from 'components/Form';
import Heading from 'components/Heading';
import Link from 'components/Link';
import Page from 'components/Page';
import Text from 'components/Text';
import Section from 'components/Section';
import client from 'client';
import useAuth from 'Authentication';
import useMessages from 'Messages';

const New = (props) => {
  const [account, setAccount] = useAuth();

  const [alert, setAlert] = useState();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAuthed, setIsAuthed] = useState(!!account.token);

  const handleFieldChange = (setField) => (event) => {
    setField(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    client.sessions
      .create({ email, password })
      .then(({ error, message, token }) => {
        if (error) return setAlert({ level: 'error', value: message });

        setAccount({ token });
        setIsAuthed(true);
      });
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirect = params.get('return') || '/clips';

  const [messages, setMessages] = useMessages();

  useEffect(() => {
    const message = messages.find((m) => m.type === 'alert');

    setAlert(message);

    return () => setMessages((ms) => ms.filter((m) => m !== message));
  }, [messages, setMessages]);

  if (isAuthed) return <Redirect to={redirect} />;

  return (
    <Page centered dim>
      <Section small>
        {alert && (
          <Alert icon={alert.level} level={alert.level}>
            {alert.value}
          </Alert>
        )}
        <Card stretch>
          <Heading center status="1">
            Sign In
          </Heading>
          <Form onSubmit={handleSubmit}>
            <Field
              name="email"
              onChange={handleFieldChange(setEmail)}
              placeholder="Email Address"
              type="email"
              value={email}
            />
            <Field
              name="password"
              onChange={handleFieldChange(setPassword)}
              placeholder="Password"
              type="password"
              value={password}
            />
            <Button stretch>Sign In</Button>
            <Box flex id="forgot-password" justifyContent="center" mt="1rem">
              <Link to="/emails/reset_password">
                <Text tiny>Forgot password?</Text>
              </Link>
            </Box>
          </Form>
        </Card>
      </Section>
    </Page>
  );
};

export default New;
