import React from 'react';

import Heading from 'components/Heading';
import ListSelect from 'components/ListSelect';
import Voice, { VoiceHeader } from './Voice';
import { parseVoice } from 'voices';

const VoiceSelect = ({ items, onSelect, select }) => {
  const voices = items.map(parseVoice);
  const voiceIndex = select && items.indexOf(select);
  const voice = select && voices[voiceIndex];

  return (
    <>
      <Heading status="2">Voice</Heading>
      <ListSelect
        header={<VoiceHeader />}
        items={voices}
        select={voice}
        onSelect={(voice, i) => onSelect(voice && items[i])}
      >
        {(item) => <Voice voice={item} />}
      </ListSelect>
    </>
  );
};

export default VoiceSelect;
