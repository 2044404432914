import React from 'react';

import Icon from 'components/Icon';
import Heading from 'components/Heading';
import Section from 'components/Section';
import Page from 'components/Page';
import Table, { Column, Row } from 'components/Table';
import Text from 'components/Text';

import './Pricing.css';

const Checkmark = <Icon icon="checkmark" />;

// prettier-ignore
const features = [
  ['Price', 'Free', '$5 / month', '$15 / month'],
  [<>Characters per month <br /> (Standard quality)</>, '5K', '200K', '1M' ],
  [<>Characters per month <br /> (WaveNet quality)</>, '1,250', '50K', '250K' ],
  ['Access to all voices', Checkmark, Checkmark, Checkmark],
  ['History & Sharing', Checkmark, Checkmark, Checkmark],
  ['Create multiple clips at once', '10', '50', '50'],
  ['Download multiple clips at once', '—', '50', '50'],
  ['File Upload', '—', '.txt', '.txt']
];

const Pricing = () => {
  return (
    <Page id="pricing">
      <Section>
        <Heading status="1">Pricing</Heading>
        <Text>
          Hearling offers <strong>free</strong> and paid versions so that you
          can choose your preferred experience based on your expected usage.
        </Text>
        <Table>
          <Row header rounded>
            <Column hidden />
            <Column>Free</Column>
            <Column highlight>Pro</Column>
            <Column highlight>Pro Plus</Column>
          </Row>
          {features.map((row, i) => (
            <Row key={i}>
              {row.map((column, i) => (
                <Column key={i}>{column}</Column>
              ))}
            </Row>
          ))}
        </Table>
      </Section>
    </Page>
  );
};

export default Pricing;
