import React from 'react';

import Icon from 'components/Icon';

import './List.css';

const List = (props) => {
  const { children, className, header, id, loading } = props;

  let classname = 'list';
  classname = classname + (className ? ` ${className}` : '');

  return (
    <div className={classname} id={id}>
      {header}
      <ul className="list__items">
        {loading ? (
          <ListItem>
            <Icon icon="loading" spin />
          </ListItem>
        ) : (
          children
        )}
      </ul>
    </div>
  );
};

const ListHeader = ({ children }) => {
  return <div className="list-header">{children}</div>;
};

const ListItem = (props) => {
  const { children, className, id, flush, onClick, selected } = props;

  let classname = 'list-item';
  classname = classname + (flush ? ' list-item--flush' : '');
  classname = classname + (onClick ? ' list-item--clickable' : '');
  classname = classname + (selected ? ' list-item--selected' : '');
  classname = classname + (className ? ` ${className}` : '');

  return (
    <li className={classname} id={id} onClick={onClick}>
      {children}
    </li>
  );
};

export default List;
export { ListHeader, ListItem };
