const env = process.env;

const config = {
  api: {
    hearling: env.REACT_APP_HEARLING_API,
  },
  sentry: {
    release: `hearling-web:${env.REACT_APP_VERSION}`,
  },
  stripe: {
    apiKey: env.REACT_APP_STRIPE_API_KEY,
  },
};

export default config;
