import React from 'react';

import Heading from 'components/Heading';
import ListSelect from 'components/ListSelect';

const LanguageSelect = ({ items, onSelect, select }) => {
  return (
    <>
      <Heading status="2" border>
        Language
      </Heading>
      <ListSelect
        items={items}
        loading={!items.length}
        select={select}
        onSelect={onSelect}
      />
    </>
  );
};

export default LanguageSelect;
