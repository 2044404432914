import React from 'react';

import './Text.css';

const Text = (props) => {
  const {
    bold,
    caps,
    center,
    children,
    className,
    lines,
    id,
    underline,
    tiny,
  } = props;

  let classname = 'text';
  classname = classname + (bold ? ' text--bold' : '');
  classname = classname + (caps ? ' text--caps' : '');
  classname = classname + (center ? ' text--center' : '');
  classname = classname + (tiny ? ' text--tiny' : '');
  classname = classname + (underline ? ' text--underline' : '');
  classname = classname + (className ? ` ${className}` : '');

  const maxHeight = lines ? `${lines * 1.5}rem` : null;

  const style = { maxHeight };

  return (
    <p className={classname} id={id} style={style}>
      {children}
    </p>
  );
};

const NoBreak = ({ children }) => <span className="no-break">{children}</span>;

const Strong = ({ children, className }) => {
  let classname = 'text__strong';
  classname = classname + (className ? ` ${className}` : '');

  return <span className={classname}>{children}</span>;
};

export default Text;
export { NoBreak, Strong };
