import React from 'react';
import * as V from 'victory';

import dates from 'dates';

const Cumulative = ({ data }) => {
  const creations = data.map((d) => d.createdAt * 1000).sort();

  const groupedByMonth = creations.reduce((data, time) => {
    const date = new Date(time);
    const month = date.getUTCMonth();
    const year = date.getUTCFullYear();
    const key = `${year}-${month}`;

    return { ...data, [key]: data[key] ? data[key] + 1 : 1 };
  }, {});

  const monthly = Object.entries(groupedByMonth).map(([month, count]) => ({
    month,
    count,
  }));

  const monthlyMaxima = Math.max(...monthly.map((d) => d.count));

  const cumulative = monthly.reduce(
    (data, { month, count }) => [
      ...data,
      {
        month,
        count: count + (data[data.length - 1]?.count || 0),
      },
    ],
    []
  );

  const cumulativeMaxima = Math.max(...cumulative.map((c) => c.count));

  return (
    <V.VictoryChart
      containerComponent={
        <V.VictoryVoronoiContainer
          labels={({ datum }) => datum.count}
          labelComponent={<V.VictoryTooltip />}
        />
      }
      domain={{ y: [0, 1] }}
      scale={{ x: 'time' }}
    >
      <V.VictoryLegend
        data={[
          { name: 'Cumulative', symbol: { fill: '#fef0f7' } },
          { name: 'Monthly', symbol: { fill: '#df2076' } },
        ]}
        orientation="horizontal"
      />
      <V.VictoryAxis fixLabelOverlap tickFormat={dates.format.chartTick} />
      <V.VictoryAxis
        dependentAxis
        tickValues={[0.25, 0.5, 0.75, 1]}
        tickFormat={(t) => Math.round(t * cumulativeMaxima)}
      />
      <V.VictoryAxis
        dependentAxis
        orientation="right"
        tickValues={[0.25, 0.5, 0.75, 1]}
        tickFormat={(t) => Math.round(t * monthlyMaxima)}
      />
      <V.VictoryArea
        data={cumulative}
        style={{ data: { fill: '#fef0f7' } }}
        x="month"
        y={(datum) => datum.count / cumulativeMaxima}
      />
      <V.VictoryLine
        data={monthly}
        style={{ data: { stroke: '#df2076' } }}
        x="month"
        y={(datum) => datum.count / monthlyMaxima}
      />
    </V.VictoryChart>
  );
};

export default Cumulative;
