import React from 'react';

import Card from 'components/Card';
import Grid, { GridItem } from '../components/Grid';
import Heading from 'components/Heading';
import Text from 'components/Text';

import Cumulative from '../Cumulative';
import LanguageBars from '../LanguageBars';
import QualityPie from '../QualityPie';

const toStartOfMonth = (date) => {
  date.setUTCDate(0);
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);

  return date;
};

const Clips = ({ clips }) => {
  const startOfMonth = toStartOfMonth(new Date());

  const clipsThisMonth = clips.filter(
    (c) => new Date(c.createdAt * 1000) >= startOfMonth
  );

  return (
    <Grid>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>
            Clips Created
            <br />
            (All Time)
          </Text>
          <Heading center status="2">
            {clips.length.toLocaleString()}
          </Heading>
        </Card>
      </GridItem>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>
            Clips Created
            <br />
            (This Month)
          </Text>
          <Heading center status="2">
            {clipsThisMonth.length.toLocaleString()}
          </Heading>
        </Card>
      </GridItem>
      <GridItem sm="1" md="1-2">
        <Card>
          <Cumulative data={clips} />
        </Card>
      </GridItem>
      <GridItem sm="1" md="1-2">
        <Card>
          <LanguageBars data={clips} />
        </Card>
      </GridItem>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>Voice Quality</Text>
          <QualityPie data={clips} />
        </Card>
      </GridItem>
    </Grid>
  );
};

export default Clips;
