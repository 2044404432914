import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';

import './ProLock.css';

const ProLock = ({ hide }) => {
  if (hide) return null;

  return (
    <Link className="pro-lock" to="/accounts/upgrade">
      <Icon icon="lock" /> Upgrade to Pro
    </Link>
  );
};

export default ProLock;
