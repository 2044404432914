import React, { useEffect, useReducer, useRef } from 'react';

import Box from 'components/Box';
import Button from 'components/Button';
import Clips from './Clips';
import Heading from 'components/Heading';
import Page from 'components/Page';
import Section from 'components/Section';
import client from 'client';
import { SearchField } from 'components/Form';

import welcomeMP3 from './welcome.mp3';

const introClip = () => ({
  createdAt: new Date(),
  location: welcomeMP3,
  text: 'Welcome to Hearling.',
  voice: {
    gender: 'female',
    languageCode: 'en-US',
    name: 'en-US-Wavenet-F',
    type: 'wavenet',
  },
});

const ListClips = () => {
  const timer = useRef(null);
  const [state, dispatch] = useReducer(
    (state, { type, payload }) => {
      switch (type) {
        case 'loading':
          return { ...state, isLoading: true };
        case 'loaded':
          return {
            ...state,
            clips: payload.clips,
            isLoading: false,
            lastPage: payload.lastPage,
            page: payload.page,
          };
        case 'search':
          return { ...state, clips: [], isLoading: true, search: payload };
        default:
          throw new Error(`Missing reducer action: ${type}`);
      }
    },
    { clips: [], isLoading: true, lastPage: 0, page: 0, search: '' }
  );

  const { clips, isLoading, lastPage, page, search } = state;

  useEffect(() => {
    (async () => {
      const res = await client.clips.list({ page: 0, search: '' });

      if (res.error) throw new Error(res.error);

      if (res.clips.length > 0)
        return dispatch({ type: 'loaded', payload: res });

      const payload = { clips: [introClip()], lastPage: 0, page: 0 };
      dispatch({ type: 'loaded', payload });
    })();
  }, []);

  const handleLoadMore = () => {
    dispatch({ type: 'loading' });

    (async () => {
      const res = await client.clips.list({ page: page + 1, search });
      const clips = [...state.clips, ...res.clips];
      const payload = { ...res, clips };
      dispatch({ type: 'loaded', payload });
    })();
  };

  const handleSearch = (search) => {
    if (timer.current) clearTimeout(timer.current);

    timer.current = setTimeout(async () => {
      const res = await client.clips.list({ page: 0, search });
      dispatch({ type: 'loaded', payload: res });
    }, 1000);

    dispatch({ type: 'search', payload: search });
  };

  const isFullLoad = isLoading && clips.length === 0;
  const isPageLoad = isLoading && clips.length > 0;
  const isLastPage = page === lastPage;
  const hasMorePages = !isFullLoad && !isLastPage;

  return (
    <Page>
      <Section id="clips-list">
        <Box alignItems="flex-end" flex justifyContent="space-between">
          <Heading status="1">Past Clips</Heading>
          <Button link="/clips/new">+ New Clip</Button>
        </Box>
        <SearchBar onSearch={handleSearch} search={search} />
        <Clips clips={clips} loading={isFullLoad} />
        {hasMorePages && (
          <LoadMoreButton loading={isPageLoad} onLoadMore={handleLoadMore} />
        )}
      </Section>
    </Page>
  );
};

const SearchBar = ({ onSearch, search }) => (
  <Box mt="1rem">
    <SearchField
      onSearch={onSearch}
      search={search}
      placeholder="Search clips..."
    />
  </Box>
);

const LoadMoreButton = ({ loading, onLoadMore }) => {
  return (
    <Box flex justifyContent="center" mt="2rem">
      <Button loading={loading} onClick={onLoadMore} secondary>
        Load More
      </Button>
    </Box>
  );
};

export default ListClips;
