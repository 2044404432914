import React from 'react';

import './Grid.css';

const Grid = ({ children }) => {
  return <div className="gridded">{children}</div>;
};

const GridItem = ({ children, sm, md }) => {
  let classname = 'grid-item';
  classname = classname + (sm ? ` grid-item--sm-${sm}` : '');
  classname = classname + (md ? ` grid-item--md-${md}` : '');

  return <div className={classname}>{children}</div>;
};

export default Grid;
export { GridItem };
