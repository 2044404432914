import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import './Link.css';

const Link = (props) => {
  const { children, className, external, onClick, to } = props;

  let classname = 'link';
  classname = classname + (className ? ` ${className}` : '');

  return onClick ? (
    <button className={classname} onClick={onClick}>
      {children}
    </button>
  ) : external ? (
    <a className={classname} href={to}>
      {children}
    </a>
  ) : (
    <RouterLink className={classname} to={to}>
      {children}
    </RouterLink>
  );
};

export default Link;
