import React from 'react';

import Card from 'components/Card';
import Grid, { GridItem } from '../components/Grid';
import Heading from 'components/Heading';
import Text from 'components/Text';

import Cumulative from '../Cumulative';

const Accounts = ({ accounts }) => {
  const pros = accounts.filter((a) => a.type === 'pro');
  const verifieds = accounts.filter((a) => a.verified);

  return (
    <Grid>
      <GridItem sm="1" md="1-2">
        <Card>
          <Cumulative data={accounts} />
        </Card>
      </GridItem>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>Total Accounts</Text>
          <Heading center status="2">
            {accounts.length.toLocaleString()}
          </Heading>
        </Card>
      </GridItem>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>Pro Accounts</Text>
          <Heading center status="2">
            {pros.length.toLocaleString()}
          </Heading>
        </Card>
      </GridItem>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>Verified Accounts</Text>
          <Heading center status="2">
            {verifieds.length.toLocaleString()}
          </Heading>
        </Card>
      </GridItem>
    </Grid>
  );
};

export default Accounts;
