import React, { useEffect, useState } from 'react';

import Alert from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button';
import Columns, { Column } from 'components/Columns';
import Heading from 'components/Heading';
import Icon, { AudioIcon, DownloadIcon } from 'components/Icon';
import Link from 'components/Link';
import List, { ListHeader, ListItem } from 'components/List';
import Page from 'components/Page';
import ProLock from 'shared/ProLock';
import Section from 'components/Section';
import Text from 'components/Text';
import client from 'client';
import useMessages from 'Messages';

import './Download.css';

const Download = (props) => {
  const { onStartOver, voice } = props;

  const [account, setAccount] = useState();
  const [alert, setAlert] = useState();
  const [clips, setClips] = useState(props.clips);

  const [, setMessages] = useMessages();

  useEffect(() => {
    client.accounts.profile().then(setAccount);
  }, []);

  useEffect(() => {
    let effectClips = props.clips;

    const setClip = ({ id, error, location }, i) => {
      const clip = { ...effectClips[i], id, error, location };
      effectClips = effectClips.map((c, j) => (j === i ? clip : c));

      setClips(effectClips);
    };

    const createClip = async ({ text }, i) => {
      const res = await client.clips.create({ text, voice: voice.name });

      if (res.error) return setClip({ error: res.message }, i);

      setClip({ ...res.clip }, i);

      setMessages((ms) => [...ms, { type: 'clip_created' }]);
    };

    props.clips.map((clip, i) =>
      createClip(clip, i).catch(() => {
        setClip({ error: 'An unexpected error occurred.' }, i);
      })
    );
  }, [props.clips, setMessages, voice]);

  const handleDownloadAll = () => {
    const clipIds = clips.map(({ id }) => id);

    (async () => {
      const res = await client.clips.archive({ clips: clipIds });

      if (res.error) return setAlert(res.message);

      const url = URL.createObjectURL(res.blob);

      window.location = url;

      URL.revokeObjectURL(url);
    })().catch(() => setAlert('An unexpected error occurred.'));
  };

  const hasFinishedLoading = clips.every(({ id }) => id);
  const isPro = account?.type?.includes('pro');

  return (
    <Page>
      <Section>
        <Heading status="1">Download Clips</Heading>
        {alert && (
          <Alert icon="error" level="error">
            {alert}
          </Alert>
        )}
        <List header={<ClipsHeader />}>
          {clips.map((clip, i) => (
            <Clip clip={clip} key={i} />
          ))}
        </List>
        <Columns id="download-actions">
          <Column>
            <Button
              disabled={!isPro}
              loading={!hasFinishedLoading}
              onClick={handleDownloadAll}
            >
              Download All (.zip)
            </Button>
            {hasFinishedLoading && <ProLock hide={isPro} />}
          </Column>
          <Column float>
            <Box
              alignItems="center"
              flex
              justifyContent="flex-end"
              p="0.75rem 1rem"
            >
              <Link onClick={onStartOver}>Start Over</Link>
            </Box>
          </Column>
        </Columns>
      </Section>
    </Page>
  );
};

const ClipsHeader = () => (
  <ListHeader>
    <Columns>
      <Column auto>Text</Column>
      <Column width="1.875rem">Play</Column>
      <Column width="4.25rem">Download</Column>
    </Columns>
  </ListHeader>
);

const Clip = ({ clip }) => {
  const { error, location, text } = clip;

  return (
    <ListItem>
      <Columns>
        <Column auto>
          <Text lines={2}>{text}</Text>
        </Column>
        <Column width="1.875rem">
          {location ? (
            <AudioIcon src={location} />
          ) : error ? (
            <Icon icon="error" />
          ) : (
            <Icon icon="loading" spin />
          )}
        </Column>
        <Column width="4.25rem">
          {location ? (
            <DownloadIcon src={location} />
          ) : error ? (
            <Icon icon="error" />
          ) : (
            <Icon icon="loading" spin />
          )}
        </Column>
      </Columns>
    </ListItem>
  );
};

export default Download;
