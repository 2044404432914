import React, { useState } from 'react';

import ClipForm from './ClipForm';
import Download from './Download';
import VoiceSelection from './VoiceSelection';

const New = (props) => {
  const [voice, setVoice] = useState(null);
  const [clips, setClips] = useState(null);

  const startOver = () => {
    setVoice(null);
    setClips(null);
  };

  return !voice ? (
    <VoiceSelection onSelect={setVoice} />
  ) : !clips ? (
    <ClipForm onBack={() => setVoice(null)} onSubmit={setClips} voice={voice} />
  ) : (
    <Download clips={clips} onStartOver={startOver} voice={voice} />
  );
};

export default New;
