import React from 'react';

import Card from 'components/Card';
import Grid, { GridItem } from '../components/Grid';
import Heading from 'components/Heading';
import Text from 'components/Text';

const toStartOfMonth = (date) => {
  date.setUTCDate(0);
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);

  return date;
};

const sum = (as, fn) => as.reduce((s, a) => s + fn(a), 0);

const Characters = ({ clips }) => {
  const startOfMonth = toStartOfMonth(new Date());

  const clipsThisMonth = clips.filter(
    (c) => new Date(c.createdAt * 1000) >= startOfMonth
  );

  const stdClips = clipsThisMonth.filter((c) => c.type === 'standard');
  const wvnClips = clipsThisMonth.filter((c) => c.type === 'wavenet');

  const charsAllTime = sum(clips, (c) => c.length);
  const charsThisMonth = sum(clipsThisMonth, (c) => c.length);
  const stdChars = sum(stdClips, (c) => c.length);
  const wvnChars = sum(wvnClips, (c) => c.length);

  return (
    <Grid>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>
            Characters Used
            <br />
            (All Time)
          </Text>
          <Heading center status="2">
            {charsAllTime.toLocaleString()}
          </Heading>
        </Card>
      </GridItem>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>
            Characters Used
            <br />
            (This Month)
          </Text>
          <Heading center status="2">
            {charsThisMonth.toLocaleString()}
          </Heading>
        </Card>
      </GridItem>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>
            Standard Chars Used
            <br />
            (This Month)
          </Text>
          <Heading center status="2">
            {stdChars.toLocaleString()}
          </Heading>
        </Card>
      </GridItem>
      <GridItem sm="1-2" md="1-4">
        <Card>
          <Text center>
            WaveNet Chars Used
            <br />
            (This Month)
          </Text>
          <Heading center status="2">
            {wvnChars.toLocaleString()}
          </Heading>
        </Card>
      </GridItem>
    </Grid>
  );
};

export default Characters;
