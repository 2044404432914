import React from 'react';

import './Section.css';

const Section = (props) => {
  const { children, id, small, large } = props;

  let classname = 'section';
  classname = classname + (small ? ' section--small' : '');
  classname = classname + (large ? ' section--large' : '');

  return (
    <section className={classname} id={id}>
      {children}
    </section>
  );
};

export default Section;
