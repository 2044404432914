import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import Alert from 'components/Alert';
import Button from 'components/Button';
import Card from 'components/Card';
import Form, { Field } from 'components/Form';
import Heading from 'components/Heading';
import Page from 'components/Page';
import Section from 'components/Section';
import client from 'client';
import useAuth from 'Authentication';

const New = () => {
  const [alert, setAlert] = useState();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [password, setPassword] = useState('');
  const [isAuthed, setIsAuthed] = useState(false);
  const [, setAccount] = useAuth();

  const handleFieldChange = (setField) => (event) =>
    setField(event.target.value);

  const handleResponse = ({ error, message, token }) => {
    setIsSubmitting(false);

    if (error) return setAlert(message);

    setAccount({ token });
    setIsAuthed(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isSubmitting) return;

    setIsSubmitting(true);

    client.accounts.create({ email, password }).then(handleResponse);
  };

  if (isAuthed) return <Redirect to="/accounts/upgrade?return=/clips" />;

  return (
    <Page centered dim>
      <Section small>
        {alert && (
          <Alert icon="error" level="error">
            {alert}
          </Alert>
        )}
        <Card stretch>
          <Heading center status="1">
            Sign Up
          </Heading>
          <Form onSubmit={handleSubmit}>
            <Field
              name="email"
              onChange={handleFieldChange(setEmail)}
              placeholder="Email Address"
              type="email"
              value={email}
            />
            <Field
              name="password"
              onChange={handleFieldChange(setPassword)}
              placeholder="Password"
              type="password"
              value={password}
            />
            <Button disabled={isSubmitting} loading={isSubmitting} stretch>
              Sign Up
            </Button>
          </Form>
        </Card>
      </Section>
    </Page>
  );
};

export default New;
