import React from 'react';

import Analytics from './Analytics';
import Header from 'shared/Header';
import Routes from 'Routes';
import client from 'client';
import config from 'config';
import useAuth, { AuthProvider } from 'Authentication';
import { BreakpointProvider } from 'Breakpoints';
import { MessageProvider } from 'Messages';

import './App.css';

client.config.setUrl(config.api.hearling);

const App = () => (
  <Analytics>
    <AuthProvider>
      <BreakpointProvider>
        <MessageProvider>
          <Middleware />
        </MessageProvider>
      </BreakpointProvider>
    </AuthProvider>
  </Analytics>
);

const Middleware = () => {
  const [account] = useAuth();

  if (account.token) client.config.setAuthToken(account.token);

  return (
    <div className="app">
      <Header />
      <Routes />
    </div>
  );
};

export default App;
