import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import Accounts from 'Accounts';
import Clips from 'Clips';
import Emails from 'Emails';
import Footer from 'shared/Footer';
import Help from 'Help';
import Home from 'Home';
import Open from 'Open';
import Pricing from 'Pricing';
import Sessions from 'Sessions';
import Subscriptions from 'Subscriptions';
import Voices from 'Voices';
import { PrivateRoute } from 'Authentication';

const routes = [
  { path: '/accounts/deleted', children: <Accounts.Deleted /> },
  {
    path: '/accounts/me/delete',
    children: <Accounts.Delete />,
    isPrivate: true,
  },
  {
    path: '/accounts/me/password',
    children: <Accounts.Password />,
    isPrivate: true,
  },
  {
    path: '/accounts/me',
    children: <Accounts.Profile />,
    exact: true,
    isPrivate: true,
  },
  { path: '/accounts/new', children: <Accounts.New /> },
  { path: '/accounts/reset_password', children: <Accounts.ResetPassword /> },
  {
    path: '/accounts/upgrade',
    children: <Accounts.Upgrade />,
    isPrivate: true,
  },
  { path: '/accounts/verify', children: <Accounts.Verify />, isPrivate: true },

  { path: '/clips', children: <Clips.List />, exact: true, isPrivate: true },
  { path: '/clips/new', children: <Clips.New />, isPrivate: true },

  { path: '/emails/reset_password', children: <Emails.ResetPassword /> },

  { path: '/help', children: <Help /> },

  { path: '/open', children: <Open /> },

  { path: '/pricing', children: <Pricing /> },

  { path: '/sessions/new', children: <Sessions.New /> },
  { path: '/sessions/delete', children: <Sessions.Delete /> },

  {
    path: '/subscriptions/success',
    children: <Subscriptions.Success />,
    isPrivate: true,
  },
  {
    path: '/subscriptions/cancel',
    children: <Subscriptions.Cancel />,
    isPrivate: true,
  },

  { path: '/voices', children: <Voices /> },

  { path: '/', children: <Home />, exact: true },
];

const Routes = () => (
  <Switch>
    {routes.map((route) =>
      route.isPrivate ? (
        <PrivateRoute exact={route.exact} key={route.path} path={route.path}>
          {route.children}
        </PrivateRoute>
      ) : (
        <Route exact={route.exact} key={route.path} path={route.path}>
          {route.children}
          <Footer />
        </Route>
      )
    )}
    <Redirect to="/" />
  </Switch>
);

export default Routes;
export { routes };
