import React from 'react';

import Heading from 'components/Heading';
import Link from 'components/Link';
import Page from 'components/Page';
import Section from 'components/Section';
import Text from 'components/Text';

const Help = () => {
  return (
    <Page>
      <Section id="help">
        <Heading status="1">Help</Heading>
        <Heading status="2">Contact</Heading>
        <Text>
          <strong>Email: </strong>
          <Link external to="mailto:contact@hearling.com">
            contact@hearling.com
          </Link>
        </Text>
        <Text>
          <strong>Twitter: </strong>
          <Link external to="https://twitter.com/HearlingApp">
            @HearlingApp
          </Link>
        </Text>
        <Text>
          <strong>Discord Server: </strong>
          <Link external to="https://discord.gg/8Gy9fjX">
            Invite
          </Link>
        </Text>
        <Heading status="2">FAQ</Heading>
        <Heading status="3">Why can't I enter more text?</Heading>
        <Text>
          If you are trying to create a clip and are unable to enter any more
          text into the text box, then you have probably hit your monthly usage
          limit. If you need more characters, you can upgrade to a plan with
          higher usage limits.
        </Text>
        <Text>
          Check out your <Link to="/accounts/me">Account</Link> page for a
          detailed breakdown of your monthly character usage.
        </Text>
        <Heading status="3">How can I get more characters?</Heading>
        <Text>
          If you are a free user, you can visit the{' '}
          <Link to="/accounts/upgrade">Upgrade</Link> page to subscribe to a
          plan with higher usage limits. Check out the{' '}
          <Link to="/pricing">pricing</Link> page for details.
        </Text>
        <Text>
          If you are already on the Pro plan, and would like to change to Pro
          plus, then please read the question below this one.
        </Text>
        <Heading status="3">How do I change subscription plans?</Heading>
        <Text>
          If you would like to change from Pro to Pro Plus (or vice versa), you
          will first need to cancel your subscription and then subscribe to the
          plan you want to be on.
        </Text>
        <Text>
          You can cancel your subscription by clicking on "Manage Billing" from
          your <Link to="/accounts/me">Account</Link> page, and then follow the
          prompts to cancel your plan. At this point, you can wait until the end
          of your billing period and then resubscribe, or click on "Cancel
          Immediately" from your Account page to end your subscription
          immediately.
        </Text>
        <Text>
          Please note that this will reset your billing period and monthly
          character usage.
        </Text>
        <Heading status="3">
          What is the difference between “Standard” and “WaveNet” voices?
        </Heading>
        <Text>
          "Standard" refers to <em>older</em> methods of speech synthesis
          ("text-to-speech"). This is generally good quality, but still has a
          robotic-like sound to the voices.
        </Text>
        <Text>
          “WaveNet” refers to voices that are trained using machine-learning to
          synthesize speech, which is typically more humanlike.
        </Text>
        <Text>
          All voices on Hearling currently come from the Google Cloud
          text-to-speech platform. You can learn more about the different voices
          in the{' '}
          <Link
            external
            to="https://cloud.google.com/text-to-speech/docs/wavenet"
          >
            Google Cloud documentation
          </Link>
          .
        </Text>
        <Heading status="3">
          Can I use the audio clips for commercial purposes?
        </Heading>
        <Text>
          Yes, you can. If you need more details about service agreements, feel
          free to email me. Contact info is at the top of the page.
        </Text>
        <Heading status="3">Why are there limits for Pro users?</Heading>
        <Text>
          Currently, Hearling is in <strong>early access</strong>. That means
          Hearling has not been thoroughly tested and unexpected errors could be
          common. Limits are simply a safety precaution until everything has
          been proven to be running smoothly. I cannot afford an
          accidentally-way-too-big bill from Google.
        </Text>
        <Heading status="3">Can I leave feedback?</Heading>
        <Text>
          Absolutely! I love to hear any feedback you might have – positive or
          negative. Without feedback, it is hard for me to know what to work on.
          If you found a bug, I will try to work on it as soon as possible. So,
          please contact me! My info is at the top of the page.
        </Text>
        <Heading status="3">Where can I get updates on Hearling?</Heading>
        <Text>
          If you want to know what has recently been released, I recommend
          following the Hearling twitter:{' '}
          <Link external to="https://twitter.com/HearlingApp">
            @HearlingApp
          </Link>
          .
        </Text>
        <Heading status="3">I still need help!</Heading>
        <Text>
          If you need help with something, found a bug, or have a question that
          was not answered here, then please reach out! My contact info is at
          the top of the page. I recommend using a public channel (Twitter or
          Discord) if you have a question that other users might also be
          interested in.
        </Text>
      </Section>
    </Page>
  );
};

export default Help;
