import React from 'react';

import Box from 'components/Box';
import Button from 'components/Button';
import Columns, { Column } from 'components/Columns';
import Heading from 'components/Heading';
import Link from 'components/Link';
import List, { ListItem } from 'components/List';
import Section from 'components/Section';
import Text from 'components/Text';
import { toLanguage, toDialect } from 'voices';

import './Queue.css';

const Queue = (props) => {
  const {
    account,
    clips,
    limit,
    onBack,
    onCreate,
    onSelect,
    onSubmit,
    voice,
  } = props;

  const isBelowClipLimit = clips.length < limit;

  const language = toLanguage(voice);
  const dialect = toDialect(voice);
  const voiceName = dialect ? `${language} (${dialect})` : language;

  return (
    <Section>
      <Heading status="1">Add Clips</Heading>
      <Heading status="2">Voice</Heading>
      <List>
        <ListItem selected>
          <Columns>
            <Column>{voiceName}</Column>
            <Column float>
              <Link onClick={() => onBack()}>Change</Link>
            </Column>
          </Columns>
        </ListItem>
      </List>
      <Heading status="2">Queue</Heading>
      <List id="queue">
        {clips.map((clip, i) => (
          <Clip key={i} clip={clip} onClick={() => onSelect(i)} />
        ))}
        {isBelowClipLimit && <NewClip onClick={onCreate} />}
      </List>
      <LimitText account={account} limit={limit} />
      <Box mt="4rem">
        <Button onClick={() => onSubmit()} stretch>
          Synthesize Clips
        </Button>
      </Box>
    </Section>
  );
};

const NewClip = ({ onClick }) => (
  <ListItem flush>
    <Button id="new-clip" onClick={onClick} stretch>
      + Add Clip
    </Button>
  </ListItem>
);

const nbsp = '\u00A0';

const LimitText = ({ account, limit }) => {
  const isPro = account?.type?.includes('pro');
  const accountType = isPro ? 'Pro' : 'Free';

  if (!account) return <Text tiny>{nbsp}</Text>;

  return (
    <Text center tiny>
      {accountType} users are limited to {limit} clips in the queue.{' '}
      {!isPro && (
        <>
          <Link to="/accounts/upgrade">Upgrade to Pro</Link> for more.
        </>
      )}
    </Text>
  );
};

const Clip = ({ clip, onClick }) => {
  const { text } = clip;

  return (
    <ListItem onClick={onClick} selected={clip.selected}>
      <Text lines={2}>{text || '(empty)'}</Text>
    </ListItem>
  );
};

export default Queue;
