import React, { useContext, useState } from 'react';

const MessageContext = React.createContext([]);

const MessageProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);

  return (
    <MessageContext.Provider value={[messages, setMessages]}>
      {children}
    </MessageContext.Provider>
  );
};

const useMessages = () => useContext(MessageContext);

export default useMessages;
export { MessageProvider };
