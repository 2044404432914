import React, { useState } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import Alert from 'components/Alert';
import Box from 'components/Box';
import Button from 'components/Button';
import Card from 'components/Card';
import Heading from 'components/Heading';
import List, { ListItem } from 'components/List';
import Page from 'components/Page';
import Section from 'components/Section';
import Text from 'components/Text';
import client from 'client';

import './Upgrade.css';

const Upgrade = () => {
  const [error, setError] = useState();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const redirect = params.get('return') || '/accounts/me';

  return (
    <Page center dim>
      <Section id="upgrade">
        <Heading status="1">Choose Plan</Heading>
        {error && (
          <Alert icon="error" level="error">
            {error}
          </Alert>
        )}
        <Box flex id="plans" mt="1rem">
          <FreePlan redirect={redirect} />
          <ProPlan onError={setError} redirect={redirect} />
          <ProPlusPlan onError={setError} redirect={redirect} />
        </Box>
      </Section>
    </Page>
  );
};

const freeFeatures = [
  <>
    <strong>6,250 characters</strong> monthly
  </>,
  'All voices and languages',
  'History and sharing',
  '10 clip queue limit',
];

const FreePlan = ({ redirect }) => {
  const [shouldRedirect, setShouldRedirect] = useState(false);

  if (shouldRedirect) return <Redirect to={redirect} />;

  return (
    <Card>
      <Box flex flexDirection="column" h="100%" justifyContent="space-between">
        <Box>
          <Heading center status="2">
            Free
          </Heading>
          <Text center bold>
            $0
          </Text>
          <Box mt="1rem">
            <Text>Try Hearling for free...</Text>
            <FeatureList items={freeFeatures} />
            <Link className="more-details" to="/pricing">
              More details {'>'}
            </Link>
          </Box>
        </Box>
        <Box flex justifyContent="center" mt="2rem">
          <Button onClick={() => setShouldRedirect(true)} stretch>
            Stay Free
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

const proFeatures = [
  <>
    <strong>250K characters</strong> monthly
  </>,
  '50 clip queue limit',
  'Bulk download (.zip)',
];

const ProPlan = ({ onError, redirect }) => {
  return (
    <Card>
      <Box flex flexDirection="column" h="100%" justifyContent="space-between">
        <Box>
          <Heading center status="2">
            Pro
          </Heading>
          <Text center bold>
            $5/month
          </Text>
          <Box mt="1rem">
            <Text>All of Free plan, plus...</Text>
            <FeatureList items={proFeatures} />
            <Link className="more-details" to="/pricing">
              More details {'>'}
            </Link>
          </Box>
        </Box>
        <Box mt="2rem">
          <ProButton onError={onError} redirect={redirect} tier="pro">
            Upgrade to Pro
          </ProButton>
        </Box>
      </Box>
    </Card>
  );
};

const ProPlusPlan = ({ onError, redirect }) => {
  return (
    <Card>
      <Box flex flexDirection="column" h="100%" justifyContent="space-between">
        <Box>
          <Heading center status="2">
            Pro Plus
          </Heading>
          <Text center bold>
            $15/month
          </Text>
          <Box mt="1rem">
            <Text>All of Pro plan, plus...</Text>
            <FeatureList
              items={[
                <>
                  <strong>1.25M characters</strong> monthly
                </>,
              ]}
            />
            <Link className="more-details" to="/pricing">
              More details {'>'}
            </Link>
          </Box>
        </Box>
        <Box mt="2rem">
          <ProButton onError={onError} redirect={redirect} tier="pro_plus">
            Upgrade to Pro Plus
          </ProButton>
        </Box>
      </Box>
    </Card>
  );
};

const ProButton = ({ children, onError, redirect: onCancel, tier }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleUpgrade = async () => {
    setIsLoading(true);

    const { error, message, redirect } = await client.subscriptions.checkout({
      onCancel,
      onSuccess: '/subscriptions/success',
      tier,
    });

    if (redirect) window.location = redirect;
    if (error) {
      setIsLoading(false);
      onError(message);
    }
  };

  return (
    <Button loading={isLoading} onClick={handleUpgrade} stretch>
      {children}
    </Button>
  );
};

const FeatureList = ({ items }) => (
  <List>
    {items.map((item, i) => (
      <ListItem flush key={i}>
        <Text>{item}</Text>
      </ListItem>
    ))}
  </List>
);

export default Upgrade;
