import React from 'react';

import Box from 'components/Box';
import Card from 'components/Card';
import Detail from '../Detail';
import Heading from 'components/Heading';
import List from 'components/List';

const AccountDetails = ({ account }) => {
  const { email } = account;
  const password = '••••••••';

  return (
    <Card>
      <Heading status="2">Account Details</Heading>
      <Box mt="2rem">
        <List className="details">
          <Detail centered title="Email" value={email} />
          <Detail
            centered
            link="/accounts/me/password"
            title="Password"
            value={password}
          />
          <Detail centered link="/accounts/me/delete" title="Delete Account" />
        </List>
      </Box>
    </Card>
  );
};

export default AccountDetails;
