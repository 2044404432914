import React, { useEffect, useState } from 'react';

import Box from 'components/Box';
import Button from 'components/Button';
import Heading from 'components/Heading';
import Page from 'components/Page';
import Section from 'components/Section';
import Text from 'components/Text';

import DialectSelect from './DialectSelect';
import LanguageSelect from './LanguageSelect';
import VoiceSelect from './VoiceSelect';
import { useDialects, useLanguages, useVoices, useVoiceList } from './hooks';
import { toLanguage, toDialect } from 'voices';

const retrieveVoice = () => {
  const voiceString = localStorage.getItem('default.voice');

  if (!voiceString) return;

  let voice;
  try {
    voice = JSON.parse(voiceString);
  } catch {
    return;
  }

  return voice;
};

const storeVoice = (voice) =>
  localStorage.setItem('default.voice', JSON.stringify(voice));

const VoiceSelection = ({ onSelect }) => {
  const [language, setLanguage] = useState(null);
  const [dialect, setDialect] = useState(null);
  const [voice, setVoice] = useState(null);

  const voices = useVoiceList();
  const languages = useLanguages(voices);
  const dialects = useDialects({ voices, language });
  const voiceItems = useVoices({ voices, language, dialect });

  useEffect(() => {
    if (dialects.length === 1) setDialect(dialects[0]);
  }, [dialects]);

  useEffect(() => {
    if (!voices) return;

    const defaultVoice = retrieveVoice();

    if (!defaultVoice) return;

    const selectedVoice = voices.find((v) => v.name === defaultVoice.name);

    if (!selectedVoice) return;

    const language = toLanguage(selectedVoice);
    const dialect = toDialect(selectedVoice);

    setLanguage(language);
    setDialect(dialect);
    setVoice(selectedVoice);
  }, [voices]);

  const handleSelect = (setState, children) => (state) => {
    if (state === null) children.map((setState) => setState(null));
    setState(state);
  };

  const handleNext = () => {
    storeVoice(voice);
    onSelect(voice);
  };

  return (
    <Page>
      <Section>
        <Heading status="1">Choose Voice</Heading>
        <Text>
          Configure the language, dialect, and quality of voice that will speak
          your text.
        </Text>
        <LanguageSelect
          items={languages}
          select={language}
          onSelect={handleSelect(setLanguage, [setDialect, setVoice])}
        />
        {language !== null && (
          <DialectSelect
            items={dialects}
            language={language}
            select={dialect}
            onSelect={handleSelect(setDialect, [setVoice])}
          />
        )}
        {dialect !== null && (
          <VoiceSelect
            dialect={dialect}
            items={voiceItems}
            language={language}
            select={voice}
            onSelect={handleSelect(setVoice, [])}
          />
        )}
        {voice !== null && (
          <Box mt="2rem">
            <Button onClick={() => handleNext()} stretch>
              Next
            </Button>
          </Box>
        )}
      </Section>
    </Page>
  );
};

export default VoiceSelection;
