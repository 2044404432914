import React from 'react';

import Columns, { Column } from 'components/Columns';
import { AudioIcon } from 'components/Icon';
import client from 'client';

import './Voice.css';

const VoiceHeader = () => (
  <Columns>
    <Column width="2.75rem">Name</Column>
    <Column width="4.125rem">Quality</Column>
    <Column width="3.375rem">Gender</Column>
    <Column float width="3.125rem">
      Sample
    </Column>
  </Columns>
);

const Voice = ({ voice }) => {
  const { gender, id, name, quality } = voice;

  const src = client.samples.get({ voice: id });

  return (
    <Columns className="voice">
      <Column width="2.75rem">{name}</Column>
      <Column width="4.125rem">{quality}</Column>
      <Column width="3.375rem">{gender}</Column>
      <Column float width="3.125rem">
        <AudioIcon src={src} />
      </Column>
    </Columns>
  );
};

export default Voice;
export { VoiceHeader };
