import React, { useEffect, useRef, useState } from 'react';

import Icon from 'components/Icon';

const AudioIcon = ({ disabled, eager, noload, src, title }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const audio = useRef(new Audio());

  audio.current.oncanplaythrough = () => setIsLoading(false);
  audio.current.onerror = () => setIsError(true);
  audio.current.onloadstart = () => setIsLoading(true);
  audio.current.onplay = () => setIsPlaying(true);
  audio.current.onpause = () => setIsPlaying(false);
  audio.current.onended = () => setIsPlaying(false);

  useEffect(() => {
    if (!eager) return;

    audio.current.src = src;
  }, [eager, src]);

  const playAudio = (event) => {
    event.stopPropagation();

    audio.current.src = src;
    audio.current.play();
  };

  const stopAudio = (event) => {
    event.stopPropagation();

    audio.current.pause();
    audio.current.currentTime = 0;
  };

  return isError ? (
    <Icon className="icon--audio" icon="error" onClick={() => {}} />
  ) : isLoading && !noload ? (
    <Icon className="icon--audio" icon="loading" spin onClick={() => {}} />
  ) : isPlaying ? (
    <Icon className="icon--audio" icon="stop" onClick={stopAudio} />
  ) : (
    <Icon
      className="icon--audio"
      disabled={disabled}
      icon="play"
      onClick={playAudio}
      title={title}
    />
  );
};

export default AudioIcon;
