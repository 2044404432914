import React from 'react';

import Icon from 'components/Icon';

import './Alert.css';

const Alert = ({ children, icon, level }) => {
  let classname = 'alert';
  classname = classname + (level ? ` alert--${level}` : '');

  return (
    <div className={classname}>
      {icon && <Icon icon={icon} />} {children}
    </div>
  );
};

export default Alert;
