import React from 'react';

import './Heading.css';

const headings = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

const Heading = (props) => {
  const { border, center, children, status } = props;

  const Component = headings[status];

  let classname = 'heading';
  classname = classname + (border ? ' heading--border' : '');
  classname = classname + (center ? ' heading--center' : '');

  return <Component className={classname}>{children}</Component>;
};

export default Heading;
