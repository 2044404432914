let defaults = {
  api: 'api.hearling.com',
  headers: () => ({
    Authorization: defaults.token ? `Bearer ${defaults.token}` : null,
    'Content-Type': 'application/json',
    'User-Agent': 'HearlingClient',
  }),
  options: {
    delete: () => ({ headers: defaults.headers(), method: 'DELETE' }),
    get: () => ({ headers: defaults.headers(), method: 'GET' }),
    patch: (params) => ({
      body: JSON.stringify(params),
      headers: defaults.headers(),
      method: 'PATCH',
    }),
    post: (params) => ({
      body: JSON.stringify(params),
      headers: defaults.headers(),
      method: 'POST',
    }),
  },
  token: null,
};

const composeRequest = ({ options, url, onSuccess }) => async (params) => {
  const res = await fetch(url(params), options(params));
  const body = await res.json().catch(() => ({}));

  if (res.status >= 500) {
    console.log(body.message);
    throw new Error(body.message);
  } else if (res.status >= 400) return { ...body, error: true };

  return onSuccess(body);
};

const setAuthToken = (token) => (defaults = { ...defaults, token });

const setUrl = (api) => {
  api = api.replace(/\/+$/, ''); // strip trailing slashes

  defaults = { ...defaults, api };
};

const config = { setAuthToken, setUrl };

const accounts = (() => {
  const create = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.post,
    url: () => `${defaults.api}/accounts`,
  });

  const del = composeRequest({
    onSuccess: () => true,
    options: defaults.options.delete,
    url: () => `${defaults.api}/accounts/me`,
  });

  const list = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.get,
    url: () => `${defaults.api}/accounts`,
  });

  const profile = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.get,
    url: () => `${defaults.api}/accounts/me`,
  });

  const resetPassword = composeRequest({
    onSuccess: () => true,
    options: defaults.options.post,
    url: () => `${defaults.api}/accounts/reset_password`,
  });

  const sendPasswordReset = composeRequest({
    onSuccess: () => true,
    options: defaults.options.post,
    url: () => `${defaults.api}/emails/reset_password`,
  });

  const sendVerificationEmail = composeRequest({
    onSuccess: () => true,
    options: defaults.options.post,
    url: () => `${defaults.api}/emails/verify`,
  });

  const update = composeRequest({
    onSuccess: () => true,
    options: defaults.options.patch,
    url: () => `${defaults.api}/accounts/me`,
  });

  const verify = composeRequest({
    onSuccess: () => true,
    options: defaults.options.get,
    url: ({ token }) => `${defaults.api}/accounts/verify?token=${token}`,
  });

  return {
    create,
    del,
    list,
    profile,
    resetPassword,
    sendPasswordReset,
    sendVerificationEmail,
    update,
    verify,
  };
})();

const clips = (() => {
  const archive = async ({ clips }) => {
    const url = `${defaults.api}/clips/archive`;
    const res = await fetch(url, defaults.options.post({ clips }));

    const body = await (res.status >= 400 ? res.json() : res.blob());

    if (res.status >= 500) {
      console.log(body.message);
      throw new Error(body.message);
    } else if (res.status >= 400) return { ...body, error: true };

    return { blob: body };
  };

  const create = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.post,
    url: () => `${defaults.api}/clips`,
  });

  const list = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.get,
    url: ({ page, search }) =>
      `${defaults.api}/clips?page=${page}&search=${search}`,
  });

  return { archive, create, list };
})();

const open = (() => {
  const data = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.get,
    url: () => `${defaults.api}/open`,
  });

  return { data };
})();

const samples = (() => {
  const get = ({ voice }) => `${defaults.api}/samples/${voice}.mp3`;

  return { get };
})();

const sessions = (() => {
  const create = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.post,
    url: () => `${defaults.api}/sessions`,
  });

  return { create };
})();

const subscriptions = (() => {
  const cancel = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.delete,
    url: () => `${defaults.api}/subscriptions/me`,
  });

  const checkout = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.post,
    url: () => `${defaults.api}/subscriptions/checkout`,
  });

  const portal = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.post,
    url: () => `${defaults.api}/subscriptions/portal`,
  });

  const show = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.get,
    url: () => `${defaults.api}/subscriptions/me`,
  });

  return { cancel, checkout, portal, show };
})();

const usage = (() => {
  const get = composeRequest({
    onSuccess: (body) => body,
    options: defaults.options.get,
    url: () => `${defaults.api}/usage/me`,
  });

  return { get };
})();

const voices = (() => {
  const list = composeRequest({
    onSuccess: ({ voices }) => voices,
    options: defaults.options.get,
    url: () => `${defaults.api}/voices`,
  });

  return { list };
})();

const client = {
  accounts,
  clips,
  config,
  open,
  samples,
  sessions,
  subscriptions,
  usage,
  voices,
};

export default client;
