import React from 'react';

import Heading from 'components/Heading';
import ListSelect from 'components/ListSelect';

const DialectSelect = ({ items, language, onSelect, select }) => {
  const format = (dialect) => (dialect ? `${language} (${dialect})` : language);

  return (
    <>
      <Heading status="2" border>
        Dialect
      </Heading>
      <ListSelect items={items} select={select} onSelect={onSelect}>
        {(item) => format(item)}
      </ListSelect>
    </>
  );
};

export default DialectSelect;
