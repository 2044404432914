import React from 'react';

import Box from 'components/Box';
import Button from 'components/Button';
import useBreakpoints from 'Breakpoints';
import { NoBreak } from 'components/Text';
import { TextArea } from 'components/Form';

import './TextEditor.css';

const placeholder = `Type the clip text here...`;

const TextEditor = React.forwardRef((props, ref) => {
  const {
    clip,
    maxLength,
    monthlyRemaining,
    onChange,
    onQuickSave,
    onSave,
  } = props;

  const breakpoints = useBreakpoints();

  return (
    <Box flex flexDirection="column" h="100%" id="editor">
      <TextArea
        autoFocus
        className="textarea--embed"
        expand
        maxLength={maxLength}
        onChange={onChange}
        onSubmit={onQuickSave}
        placeholder={placeholder}
        ref={ref}
        value={clip.text || ''}
      />
      <Box className="info-bar">
        {breakpoints.small ? (
          <Box>
            <Button onClick={onSave}>Save</Button>
          </Box>
        ) : (
          <Box className="tip">
            Tip: <strong>Ctrl + Enter</strong> to save and add to queue
          </Box>
        )}
        <Box className="tip">
          <Box>
            <strong>Characters:</strong>{' '}
            <NoBreak>
              {clip.text.length.toLocaleString()} / {maxLength.toLocaleString()}
            </NoBreak>
          </Box>
          {monthlyRemaining && (
            <Box>
              <strong>Monthly remaining:</strong>{' '}
              <NoBreak>{monthlyRemaining.toLocaleString()}</NoBreak>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
});

export default TextEditor;
