import React, { useEffect, useState } from 'react';

import Box from 'components/Box';
import Clip from './Clip';
import ClipsHeader from './ClipsHeader';
import List from 'components/List';
import Text from 'components/Text';
import dates from 'dates';

const byCreatedAt = (clip) => dates.format.dateString(clip.createdAt);

const groupBy = (xs, fn) =>
  xs.reduce((groups, x) => {
    const key = fn(x);

    if (!groups[key]) groups[key] = [];

    groups[key].push(x);

    return groups;
  }, {});

const Clips = (props) => {
  const { loading } = props;

  const [clips, setClips] = useState(props.clips);

  useEffect(() => {
    setClips((clips) => {
      const selected = clips.filter((clip) => clip.selected);

      return selected.reduce(
        (clips, s) => clips.map((c) => (c.id === s.id ? s : c)),
        props.clips
      );
    });
  }, [props.clips]);

  const selected = clips.filter((clip) => clip.selected);

  const handleSelect = (clip) => {
    if (!clip.selected && selected.length >= 50) return;

    setClips(
      clips.map((c) => (c === clip ? { ...c, selected: !c.selected } : c))
    );
  };

  const clipsByDate = groupBy(clips, byCreatedAt);

  const dates = Object.keys(clipsByDate).sort().reverse();

  return (
    <>
      <ClipsHeader selected={selected} />
      {loading && <List loading />}
      {dates.map((date) => (
        <ClipGroup
          clips={clipsByDate[date]}
          date={date}
          key={date}
          onSelect={handleSelect}
        />
      ))}
    </>
  );
};

const ClipGroup = ({ clips, date, onSelect }) => (
  <List header={<DateHeader date={date} />}>
    {clips.map((clip, i) => (
      <Clip clip={clip} key={i} onClick={onSelect} selected={clip.selected} />
    ))}
  </List>
);

const DateHeader = ({ date }) => (
  <Box ml="1rem" mt="0.75rem">
    <Text bold tiny>
      {dates.format.clipHistory(date)}
    </Text>
  </Box>
);

export default Clips;
