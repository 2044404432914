import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

const parseJWT = (token) => {
  try {
    const [, body] = token.split('.');
    const decoded = atob(body);

    return JSON.parse(decoded);
  } catch (e) {
    Sentry.captureException(e, { extra: { token } });
  }
};

const storage = (() => {
  const storeAccount = (account) =>
    localStorage.setItem('account', JSON.stringify(account));

  const retrieveAccount = () =>
    JSON.parse(localStorage.getItem('account') || '{}');

  return { storeAccount, retrieveAccount };
})();

const AccountContext = React.createContext();

const PrivateRoute = ({ children, ...rest }) => {
  const [account] = useContext(AccountContext);
  const location = useLocation();

  return (
    <Route {...rest}>
      {account.token ? (
        children
      ) : (
        <Redirect to={`/sessions/new?return=${location.pathname}`} />
      )}
    </Route>
  );
};

const AuthProvider = ({ children }) => {
  const defaultValue = storage.retrieveAccount();
  const [account, setAccountState] = useState(defaultValue);

  const setAccount = (account) => {
    storage.storeAccount(account);
    setAccountState(account);
  };

  useEffect(() => {
    if (!account.token) return Sentry.setUser();

    const fields = parseJWT(account.token);

    if (!fields) return;

    Sentry.setUser({ email: fields.email });
  }, [account.token]);

  return (
    <AccountContext.Provider value={[account, setAccount]}>
      {children}
    </AccountContext.Provider>
  );
};

const useAuth = () => useContext(AccountContext);

export default useAuth;
export { AuthProvider, PrivateRoute };
