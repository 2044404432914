import React, { useEffect, useState } from 'react';

import Heading from 'components/Heading';
import Page from 'components/Page';
import Section from 'components/Section';
import client from 'client';

import Accounts from './Accounts';
import Characters from './Characters';
import Clips from './Clips';

import './Open.css';

const restructure = (resource) =>
  resource.data.map((values) =>
    resource.keys.reduce((acc, key, i) => ({ ...acc, [key]: values[i] }), {})
  );

const Open = () => {
  const [data, setData] = useState();

  useEffect(() => {
    client.open.data().then(setData);
  }, []);

  if (!data) return <Page loading />;

  const accounts = restructure(data.accounts);

  const clips = restructure(data.clips);

  return (
    <Page dim loading={!data}>
      <Section id="open" large>
        <Heading status="1">Accounts</Heading>
        <Accounts accounts={accounts} />
        <Heading status="1">Clips</Heading>
        <Clips clips={clips} />
        <Heading status="1">Characters</Heading>
        <Characters clips={clips} />
      </Section>
    </Page>
  );
};

export default Open;
