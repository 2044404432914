import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import Page from 'components/Page';
import client from 'client';
import useMessages from 'Messages';

const msgError = {
  level: 'error',
  type: 'alert',
  value: 'Unable to verify email.',
};

const msgSuccess = {
  level: 'success',
  type: 'alert',
  value: 'Success! Thanks for verifying your email.',
};

const useQuery = () => {
  const location = useLocation();
  return new URLSearchParams(location.search);
};

const Verify = () => {
  const query = useQuery();
  const token = query.get('token');

  const [finished, setFinished] = useState(false);
  const [, setMessages] = useMessages();

  useEffect(() => {
    client.accounts.verify({ token }).then(({ error }) => {
      let msg = error ? msgError : msgSuccess;

      setMessages((ms) => [...ms, msg]);
      setFinished(true);
    });
  }, [setMessages, token]);

  if (finished) return <Redirect to="/accounts/me" />;

  return <Page centered>Attempting verification...</Page>;
};

export default Verify;
