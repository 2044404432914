import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';

import './Button.css';

const Button = (props) => {
  const {
    children,
    className,
    id,
    link,
    loading,
    onClick,
    secondary,
    stretch,
  } = props;
  const disabled = props.disabled || props.loading;

  let classname = 'button';
  classname = classname + (disabled ? ' button--disabled' : '');
  classname = classname + (loading ? ' button--loading' : '');
  classname = classname + (secondary ? ' button--secondary' : '');
  classname = classname + (stretch ? ' button--stretch' : '');
  classname = classname + (className ? ` ${className}` : '');

  const ButtonWrapper = ({ children }) => (
    <button className={classname} id={id} onClick={disabled ? null : onClick}>
      <span className="button__body">{children}</span>
      {loading && (
        <span className="button__overlay">
          <Icon icon={secondary ? 'loading' : 'loadingWhite'} spin white />
        </span>
      )}
    </button>
  );

  const LinkWrapper = ({ children }) => (
    <Link className={classname} onClick={disabled ? null : onClick} to={link}>
      {children}
    </Link>
  );

  const Component = link ? LinkWrapper : ButtonWrapper;

  return <Component>{children}</Component>;
};

export default Button;
