import React, { useContext, useEffect, useState } from 'react';

const QUERIES = [
  { name: 'small', query: '(max-width: 719px)' },
  { name: 'medium', query: '(min-width: 720px)' },
  { name: 'large', query: '(min-width: 1400px)' },
];

const defaultValue = {};

const BreakpointContext = React.createContext(defaultValue);

const BreakpointProvider = ({ children }) => {
  const [breakpoints, setBreakpoints] = useState({});

  const handleUpdate = ({ matches, name }) =>
    setBreakpoints((state) => ({
      ...state,
      [name]: matches,
    }));

  useEffect(() => {
    const _breakpoints = QUERIES.reduce((state, { name, query }) => {
      const match = window.matchMedia(query);

      match.addListener(({ matches }) => handleUpdate({ matches, name }));

      return { ...state, [name]: match.matches };
    }, {});

    setBreakpoints(_breakpoints);
  }, []);

  return (
    <BreakpointContext.Provider value={breakpoints}>
      {children}
    </BreakpointContext.Provider>
  );
};

const useBreakpoints = () => useContext(BreakpointContext);

export default useBreakpoints;
export { BreakpointProvider };
