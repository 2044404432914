import Delete from './Delete';
import Deleted from './Deleted';
import New from './New';
import Password from './Password';
import Profile from './Profile';
import ResetPassword from './ResetPassword';
import Upgrade from './Upgrade';
import Verify from './Verify';

const Accounts = {
  Delete,
  Deleted,
  New,
  Password,
  Profile,
  ResetPassword,
  Upgrade,
  Verify,
};

export default Accounts;
