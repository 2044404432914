import React from 'react';
import { Redirect } from 'react-router-dom';

import Alert from 'components/Alert';
import Button from 'components/Button';
import Card from 'components/Card';
import Form, { Field } from 'components/Form';
import Heading from 'components/Heading';
import Page from 'components/Page';
import Section from 'components/Section';
import Text from 'components/Text';
import client from 'client';

const Delete = () => {
  const [alert, setAlert] = React.useState();
  const [confirmText, setConfirmText] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState(false);

  const handleConfirmChange = (e) => setConfirmText(e.target.value);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setAlert(null);

    (async () => {
      const { error, message } = await client.accounts.del();

      if (error) return setAlert({ level: 'error', message });

      setIsDeleted(true);
    })()
      .catch(() =>
        setAlert({ level: 'error', message: 'An unexpected error occurred.' })
      )
      .finally(() => setIsSubmitting(false));
  };

  const hasConfirmed = confirmText.toUpperCase() === 'DELETE';
  const isSubmittable = !alert && !isSubmitting && hasConfirmed;

  if (isDeleted) return <Redirect to="/accounts/deleted" />;

  return (
    <Page centered dim>
      <Section small>
        {alert && (
          <Alert icon={alert.level} level={alert.level}>
            {alert.message}
          </Alert>
        )}
        <Card stretch>
          <Heading center status="1">
            Delete Account
          </Heading>
          <Text>
            Are you sure you want to delete your account? Your clips will be
            permanently deleted and this action cannot be undone.
          </Text>
          <Text>Please type "DELETE" to confirm.</Text>
          <Form onSubmit={handleSubmit}>
            <Field
              name="confirm"
              onChange={handleConfirmChange}
              placeholder="DELETE"
              value={confirmText}
            />
            <Button disabled={!isSubmittable} stretch>
              Delete My Account
            </Button>
          </Form>
        </Card>
      </Section>
    </Page>
  );
};

export default Delete;
