import React from 'react';

import Box from 'components/Box';
import Card from 'components/Card';
import Detail from '../Detail';
import Heading from 'components/Heading';
import List from 'components/List';
import Text, { Strong } from 'components/Text';
import dates from 'dates';

const Activity = ({ usage }) => {
  const sinceDate = dates.format.usageSince(usage.anchor);

  const Usage = ({ usage }) => (
    <>
      <Strong>{usage.toLocaleString()}</Strong> chars.
    </>
  );

  return (
    <Card>
      <Box alignItems="flex-end" flex justifyContent="space-between">
        <Heading status="2">Activity</Heading>
        <span>Since {sinceDate}</span>
      </Box>
      <Box mt="2rem">
        <Text bold>Standard Voices</Text>
        <List className="details">
          <Detail
            title={'Monthly Usage'}
            value={<Usage usage={usage.monthly.standard} />}
          />
          <Detail
            title={'Monthly Limit'}
            value={<Usage usage={usage.limit.standard} />}
          />
        </List>
      </Box>
      <Box mt="2rem">
        <Text bold>Wavenet Voices</Text>
        <List className="details">
          <Detail
            title={'Monthly Usage'}
            value={<Usage usage={usage.monthly.wavenet} />}
          />
          <Detail
            title={'Monthly Limit'}
            value={<Usage usage={usage.limit.wavenet} />}
          />
        </List>
      </Box>
    </Card>
  );
};

export default Activity;
