import React, { useState } from 'react';

import Button from 'components/Button';
import Card from 'components/Card';
import Form, { Field } from 'components/Form';
import Heading from 'components/Heading';
import Alert from 'components/Alert';
import Page from 'components/Page';
import Section from 'components/Section';
import Text from 'components/Text';
import client from 'client';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleEmailChange = (event) => setEmail(event.target.value);

  const handleSubmit = (event) => {
    event.preventDefault();

    client.accounts
      .sendPasswordReset({ email })
      .then(() => setHasSubmitted(true));
  };

  return (
    <Page centered dim>
      <Section small>
        {hasSubmitted && (
          <Alert icon="success" level="success">
            If this email exists in Hearling, it has been sent the reset link.
          </Alert>
        )}
        <Card stretch>
          <Heading center status="1">
            Forgot Password?
          </Heading>
          <Text>
            Enter your account email address and you will receieve a link to
            reset your password.
          </Text>
          <Form onSubmit={handleSubmit}>
            <Field
              name="email"
              onChange={handleEmailChange}
              placeholder="Email Address"
              type="email"
              value={email}
            />
            <Button stretch>Request Password Reset</Button>
          </Form>
        </Card>
      </Section>
    </Page>
  );
};

export default ResetPassword;
